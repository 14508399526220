type EditPenIconProps = {
  color?: string;
  handleClick?: () => void
}

export const EditPenIcon = ({ color = '#363BC4', handleClick, ...props }: EditPenIconProps): JSX.Element => (
  <svg
    onClick={handleClick}
    xmlns="http://www.w3.org/2000/svg"
    width="18"
    height="18"
    viewBox="0 0 18 18"
    fill="none"
    {...props}
  >
    <path
      d="M8.26183 2.50411H4.87298C3.51731 2.50411 2.83947 2.50411 2.32168 2.76794C1.86621 3.00001 1.4959 3.37032 1.26383 3.82579C1 4.34358 1 5.02142 1 6.37709V13.1548C1 14.5105 1 15.1883 1.26383 15.7061C1.4959 16.1616 1.86621 16.5319 2.32168 16.7639C2.83947 17.0278 3.51731 17.0278 4.87298 17.0278H11.6507C13.0064 17.0278 13.6842 17.0278 14.202 16.7639C14.6575 16.5319 15.0278 16.1616 15.2598 15.7061C15.5237 15.1883 15.5237 14.5105 15.5237 13.1548V9.76594M5.8412 12.1866H7.19234C7.58704 12.1866 7.7844 12.1866 7.97012 12.142C8.13478 12.1024 8.29219 12.0372 8.43657 11.9488C8.59943 11.849 8.73898 11.7094 9.01808 11.4303L16.734 3.71442C17.4024 3.04598 17.4024 1.96224 16.734 1.29381C16.0655 0.625373 14.9818 0.625372 14.3134 1.2938L6.59745 9.0097C6.31835 9.2888 6.1788 9.42835 6.079 9.5912C5.99052 9.73558 5.92532 9.893 5.88579 10.0577C5.8412 10.2434 5.8412 10.4407 5.8412 10.8354V12.1866Z"
      stroke={color}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
