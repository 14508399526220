type LightProps = {
  heigth?: string
  width?: string
  color?: string;
}

export const LightOnIcon = ({ heigth = '19', width = '19', color }: LightProps): JSX.Element => (
  <svg xmlns="http://www.w3.org/2000/svg" width={width} height={heigth} viewBox="0 0 19 19" fill="none" className="__web-inspector-hide-shortcut__">
    <path d="M9.5 1V1.85M1.85 9.5H1M3.975 3.975L3.46492 3.46492M15.025 3.975L15.5352 3.46492M18 9.5H17.15M7.8 10.775H11.2M9.5 10.775V15.025M12.475 13.6429C13.762 12.7171 14.6 11.2064 14.6 9.5C14.6 6.68335 12.3167 4.4 9.5 4.4C6.68335 4.4 4.4 6.68335 4.4 9.5C4.4 11.2064 5.23804 12.7171 6.525 13.6429V15.28C6.525 16.2321 6.525 16.7081 6.71029 17.0718C6.87327 17.3917 7.13334 17.6517 7.45322 17.8147C7.81687 18 8.29291 18 9.245 18H9.755C10.7071 18 11.1831 18 11.5468 17.8147C11.8667 17.6517 12.1267 17.3917 12.2897 17.0718C12.475 16.7081 12.475 16.2321 12.475 15.28V13.6429Z" stroke={color || '#363BC4'} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M9.5 1V1.85M1.85 9.5H1M3.975 3.975L3.46492 3.46492M15.025 3.975L15.5352 3.46492M18 9.5H17.15M7.8 10.775H11.2M9.5 10.775V15.025M12.475 13.6429C13.762 12.7171 14.6 11.2064 14.6 9.5C14.6 6.68335 12.3167 4.4 9.5 4.4C6.68335 4.4 4.4 6.68335 4.4 9.5C4.4 11.2064 5.23804 12.7171 6.525 13.6429V15.28C6.525 16.2321 6.525 16.7081 6.71029 17.0718C6.87327 17.3917 7.13334 17.6517 7.45322 17.8147C7.81687 18 8.29291 18 9.245 18H9.755C10.7071 18 11.1831 18 11.5468 17.8147C11.8667 17.6517 12.1267 17.3917 12.2897 17.0718C12.475 16.7081 12.475 16.2321 12.475 15.28V13.6429Z" stroke={color || '#363BC4'} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
  </svg>
);
