type OutOfSpecificationIconProps = {
  color?: string;
}

export const OutOfSpecificationIcon = ({ color = 'white', ...props }: OutOfSpecificationIconProps): JSX.Element => (
  <svg {...props} width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M22.5601 16.2999L14.8901 3.57994C14.58 3.09475 14.1527 2.69546 13.6476 2.41888C13.1425 2.14229 12.5759 1.99731 12.0001 1.99731C11.4243 1.99731 10.8577 2.14229 10.3526 2.41888C9.84755 2.69546 9.42024 3.09475 9.1101 3.57994L1.4401 16.2999C1.16915 16.7516 1.02189 17.2666 1.01311 17.7932C1.00434 18.3199 1.13436 18.8395 1.3901 19.2999C1.68578 19.8182 2.11376 20.2487 2.6303 20.5474C3.14684 20.846 3.73343 21.0022 4.3301 20.9999H19.6701C20.2629 21.0063 20.8468 20.8561 21.363 20.5647C21.8792 20.2732 22.3093 19.8508 22.6101 19.3399C22.8734 18.8747 23.0075 18.3476 22.9987 17.8131C22.9899 17.2787 22.8385 16.7563 22.5601 16.2999ZM12.0001 16.9999C11.8023 16.9999 11.609 16.9413 11.4445 16.8314C11.2801 16.7215 11.1519 16.5653 11.0762 16.3826C11.0005 16.1999 10.9807 15.9988 11.0193 15.8048C11.0579 15.6109 11.1531 15.4327 11.293 15.2928C11.4328 15.153 11.611 15.0577 11.805 15.0192C11.999 14.9806 12.2001 15.0004 12.3828 15.0761C12.5655 15.1517 12.7217 15.2799 12.8316 15.4444C12.9415 15.6088 13.0001 15.8022 13.0001 15.9999C13.0001 16.2652 12.8947 16.5195 12.7072 16.707C12.5197 16.8946 12.2653 16.9999 12.0001 16.9999ZM13.0001 12.9999C13.0001 13.2652 12.8947 13.5195 12.7072 13.707C12.5197 13.8946 12.2653 13.9999 12.0001 13.9999C11.7349 13.9999 11.4805 13.8946 11.293 13.707C11.1055 13.5195 11.0001 13.2652 11.0001 12.9999V8.99994C11.0001 8.73472 11.1055 8.48037 11.293 8.29283C11.4805 8.1053 11.7349 7.99994 12.0001 7.99994C12.2653 7.99994 12.5197 8.1053 12.7072 8.29283C12.8947 8.48037 13.0001 8.73472 13.0001 8.99994V12.9999Z"
      fill={color}
    />
  </svg>
);
