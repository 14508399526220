export const ClientPanelIcon = (): JSX.Element => (
  <svg width="30" height="30" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M41.0321 1.54839C38.427 1.54839 36.2469 3.39639 35.7305 5.84981L22.4298 4.992C22.2107 2.20335 19.8758 0 17.0321 0C14.0438 0 11.6128 2.43097 11.6128 5.41936C11.6128 8.40774 14.0438 10.8387 17.0321 10.8387C19.6373 10.8387 21.8174 8.99071 22.3338 6.53729L35.6345 7.3951C35.7336 8.65626 36.2654 9.79432 37.0822 10.6661L32.5284 16.3587L33.7377 17.3257L38.2907 11.6346C39.0959 12.1092 40.0311 12.3871 41.0321 12.3871C44.0205 12.3871 46.4515 9.95613 46.4515 6.96774C46.4515 3.97936 44.0205 1.54839 41.0321 1.54839V1.54839ZM17.0321 9.29032C14.8977 9.29032 13.1612 7.55381 13.1612 5.41936C13.1612 3.2849 14.8977 1.54839 17.0321 1.54839C19.1666 1.54839 20.9031 3.2849 20.9031 5.41936C20.9031 7.55381 19.1666 9.29032 17.0321 9.29032ZM41.0321 10.8387C38.8977 10.8387 37.1612 9.10219 37.1612 6.96774C37.1612 4.83329 38.8977 3.09677 41.0321 3.09677C43.1666 3.09677 44.9031 4.83329 44.9031 6.96774C44.9031 9.10219 43.1666 10.8387 41.0321 10.8387Z" fill="white" />
    <path d="M41.0323 4.64526C39.7518 4.64526 38.7097 5.68733 38.7097 6.96784C38.7097 8.24836 39.7518 9.29042 41.0323 9.29042C42.3128 9.29042 43.3549 8.24836 43.3549 6.96784C43.3549 5.68733 42.3128 4.64526 41.0323 4.64526ZM41.0323 7.74204C40.6057 7.74204 40.2581 7.39442 40.2581 6.96784C40.2581 6.54126 40.6057 6.19365 41.0323 6.19365C41.4589 6.19365 41.8065 6.54126 41.8065 6.96784C41.8065 7.39442 41.4589 7.74204 41.0323 7.74204Z" fill="white" />
    <path d="M17.0323 3.09668C15.7518 3.09668 14.7097 4.13874 14.7097 5.41926C14.7097 6.69978 15.7518 7.74184 17.0323 7.74184C18.3128 7.74184 19.3549 6.69978 19.3549 5.41926C19.3549 4.13874 18.3128 3.09668 17.0323 3.09668ZM17.0323 6.19345C16.6057 6.19345 16.2581 5.84584 16.2581 5.41926C16.2581 4.99268 16.6057 4.64507 17.0323 4.64507C17.4589 4.64507 17.8065 4.99268 17.8065 5.41926C17.8065 5.84584 17.4589 6.19345 17.0323 6.19345Z" fill="white" />
    <path d="M35.8954 22.9069C35.6376 22.0089 35.2799 21.1449 34.8263 20.3273L35.9295 18.5451L31.7775 14.3931L29.9953 15.4964C29.1777 15.0427 28.3137 14.6842 27.4157 14.4264L26.9357 12.3872H21.0642L20.5842 14.4264C19.6861 14.6842 18.8221 15.0419 18.0046 15.4964L16.2224 14.3931L12.0704 18.5451L13.1736 20.3273C12.7199 21.1449 12.3615 22.0089 12.1037 22.9069L10.0645 23.3869V28.6453H15.8043C15.5953 27.9067 15.4838 27.1279 15.4838 26.3227C15.4838 21.6195 19.2967 17.8066 23.9999 17.8066C28.7032 17.8066 32.5161 21.6195 32.5161 26.3227C32.5161 27.1279 32.4046 27.9067 32.1955 28.6453H37.9354V23.3869L35.8954 22.9069Z" fill="#ED193F" />
    <path d="M47.7042 35.6129L43.8332 27.8709H33.1563C33.2415 27.3607 33.2902 26.8436 33.2902 26.3225C33.2902 21.1997 29.1227 17.0322 23.9999 17.0322C18.8771 17.0322 14.7096 21.1997 14.7096 26.3225C14.7096 26.8436 14.7584 27.3607 14.8435 27.8709H4.16662L0.295654 35.6129H3.87088V48H44.1289V35.6129H47.7042ZM45.1989 34.0645H29.1235L26.8009 29.4193H42.8763L45.1989 34.0645ZM21.3266 27.8709C21.0533 27.4018 20.9031 26.8745 20.9031 26.3225C20.9031 24.6147 22.292 23.2258 23.9999 23.2258C25.7078 23.2258 27.0967 24.6147 27.0967 26.3225C27.0967 26.8753 26.9465 27.401 26.674 27.8709H21.3266ZM16.258 26.3225C16.258 22.0536 19.731 18.5806 23.9999 18.5806C28.2688 18.5806 31.7418 22.0536 31.7418 26.3225C31.7418 26.8467 31.6714 27.3623 31.5684 27.8709H28.3757C28.5498 27.3785 28.6451 26.8583 28.6451 26.3225C28.6451 23.7607 26.5617 21.6774 23.9999 21.6774C21.4381 21.6774 19.3547 23.7607 19.3547 26.3225C19.3547 26.8583 19.45 27.3785 19.6249 27.8709H16.4314C16.3284 27.3623 16.258 26.8459 16.258 26.3225V26.3225ZM5.12352 29.4193H22.7473L20.4247 34.0645H2.80094L5.12352 29.4193ZM5.41927 35.6129H21.3816L23.9999 30.3762V46.4516H5.41927V35.6129ZM42.5806 46.4516H25.5483V30.3762L28.1666 35.6129H42.5806V46.4516Z" fill="white" />
    <path d="M3.09668 13.9355H7.74184V15.4839H3.09668V13.9355Z" fill="#ED193F" />
    <path d="M10.8387 18.5806H3.87097C1.73652 18.5806 0 16.844 0 14.7096C0 12.5751 1.73652 10.8386 3.87097 10.8386H6.96774C9.10219 10.8386 10.8387 12.5751 10.8387 14.7096V18.5806ZM3.87097 12.387C2.59045 12.387 1.54839 13.4291 1.54839 14.7096C1.54839 15.9901 2.59045 17.0322 3.87097 17.0322H9.29032V14.7096C9.29032 13.4291 8.24826 12.387 6.96774 12.387H3.87097Z" fill="white" />
    <path d="M3.09668 13.9355H4.64507V15.4839H3.09668V13.9355Z" fill="white" />
    <path d="M6.1936 13.9355H7.74199V15.4839H6.1936V13.9355Z" fill="white" />
    <path d="M40.2581 17.0322H44.9032V18.5806H40.2581V17.0322Z" fill="#ED193F" />
    <path d="M44.1291 21.6775H37.1614V17.8065C37.1614 15.6721 38.8979 13.9355 41.0323 13.9355H44.1291C46.2636 13.9355 48.0001 15.6721 48.0001 17.8065C48.0001 19.941 46.2636 21.6775 44.1291 21.6775ZM38.7098 20.1291H44.1291C45.4096 20.1291 46.4517 19.087 46.4517 17.8065C46.4517 16.526 45.4096 15.4839 44.1291 15.4839H41.0323C39.7518 15.4839 38.7098 16.526 38.7098 17.8065V20.1291Z" fill="white" />
    <path d="M43.3547 17.0322H44.9031V18.5806H43.3547V17.0322Z" fill="white" />
    <path d="M40.2581 17.0322H41.8064V18.5806H40.2581V17.0322Z" fill="white" />
    <path d="M22.4516 44.9033H6.96777V37.1614H22.4516V44.9033Z" fill="#ED193F" />
    <path d="M17.8064 40.2581H19.3548V41.8064H17.8064V40.2581Z" fill="white" />
    <path d="M14.7097 40.2581H16.2581V41.8064H14.7097V40.2581Z" fill="white" />
    <path d="M11.6128 40.2581H13.1612V41.8064H11.6128V40.2581Z" fill="white" />
    <path d="M33.2903 41.0322H41.0322V44.9032H33.2903V41.0322Z" fill="#ED193F" />
    <path d="M39.4839 43.3547H41.0323V44.9031H39.4839V43.3547Z" fill="white" />
    <path d="M36.3872 43.3547H37.9356V44.9031H36.3872V43.3547Z" fill="white" />
    <path d="M33.2903 43.3547H34.8387V44.9031H33.2903V43.3547Z" fill="white" />
    <path d="M33.2903 40.2581H41.0322V41.8064H33.2903V40.2581Z" fill="white" />
  </svg>
);
