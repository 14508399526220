type ComparativeIconProps = {
  color?: string;
  width?: string;
  height?: string;
}

export const ComparativeIcon = ({ color = '#3F3F3F', width = '24', height = '14' }:ComparativeIconProps) => (
  <svg width={width} height={height} viewBox="0 0 24 14" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M2.73195 8.94555C2.10403 8.94939 1.4942 8.73548 1.00626 8.34025C0.518318 7.94502 0.182439 7.3929 0.0557997 6.77788C-0.0708394 6.16285 0.0195899 5.52295 0.311693 4.9671C0.603796 4.41125 1.07952 3.97382 1.65787 3.72928C2.23622 3.48473 2.88144 3.44819 3.48371 3.62587C4.08597 3.80355 4.60804 4.18447 4.96103 4.70378C5.31403 5.22309 5.47613 5.8487 5.41974 6.47409C5.36336 7.09948 5.09197 7.68599 4.65177 8.13379C4.40128 8.38924 4.10264 8.59251 3.77312 8.73184C3.44359 8.87118 3.08971 8.94381 2.73195 8.94555ZM2.73195 5.54426C2.54987 5.5445 2.37509 5.61587 2.24489 5.74314C2.14896 5.83907 2.08362 5.96129 2.05716 6.09435C2.03069 6.22741 2.04427 6.36533 2.09619 6.49067C2.14811 6.61601 2.23602 6.72314 2.34883 6.79852C2.46163 6.87389 2.59425 6.91412 2.72992 6.91412C2.86558 6.91412 2.9982 6.87389 3.11101 6.79852C3.22381 6.72314 3.31173 6.61601 3.36365 6.49067C3.41556 6.36533 3.42915 6.22741 3.40268 6.09435C3.37621 5.96129 3.31088 5.83907 3.21495 5.74314C3.15331 5.67601 3.07876 5.622 2.99577 5.58435C2.91279 5.54669 2.82305 5.52615 2.73195 5.52396V5.54426Z" fill={color} />
    <path d="M21.2483 7.56149C20.6204 7.56402 20.0111 7.34855 19.5244 6.95185C19.0377 6.55515 18.7037 6.00181 18.5796 5.38631C18.4554 4.7708 18.5488 4.13128 18.8436 3.57693C19.1385 3.02257 19.6167 2.58775 20.1965 2.34669C20.7762 2.10563 21.4217 2.07327 22.0227 2.25515C22.6237 2.43702 23.1429 2.82185 23.4917 3.34394C23.8406 3.86603 23.9974 4.493 23.9354 5.11784C23.8734 5.74268 23.5965 6.32663 23.1519 6.77002C22.6468 7.27494 21.9625 7.55948 21.2483 7.56149ZM21.2483 4.16425C21.0895 4.16425 20.9356 4.21936 20.8129 4.32018C20.6902 4.421 20.6062 4.56127 20.5754 4.71707C20.5446 4.87287 20.5688 5.03454 20.6439 5.17449C20.719 5.31444 20.8403 5.424 20.9871 5.48447C21.134 5.54494 21.2973 5.55257 21.4492 5.50607C21.601 5.45957 21.732 5.36182 21.8199 5.22949C21.9077 5.09716 21.9469 4.93845 21.9307 4.78046C21.9146 4.62246 21.8441 4.47496 21.7313 4.36313C21.6678 4.29986 21.5925 4.24973 21.5096 4.21561C21.4267 4.18148 21.3379 4.16403 21.2483 4.16425Z" fill={color} />
    <path d="M12.8831 13.4104C12.5253 13.4089 12.1713 13.3363 11.8418 13.197C11.5122 13.0577 11.2136 12.8543 10.9632 12.5986C10.523 12.1508 10.2517 11.5643 10.1953 10.9389C10.1389 10.3135 10.301 9.68794 10.654 9.16863C11.007 8.64931 11.529 8.26839 12.1313 8.09071C12.7336 7.91303 13.3788 7.94958 13.9571 8.19412C14.5355 8.43867 15.0112 8.8761 15.3033 9.43194C15.5954 9.98779 15.6859 10.6277 15.5592 11.2427C15.4326 11.8577 15.0967 12.4099 14.6088 12.8051C14.1208 13.2003 13.511 13.4142 12.8831 13.4104ZM12.8831 10.0091C12.7472 10.0091 12.6143 10.0495 12.5014 10.1251C12.3885 10.2007 12.3005 10.3082 12.2488 10.4339C12.197 10.5595 12.1838 10.6978 12.2107 10.831C12.2377 10.9642 12.3036 11.0864 12.4001 11.1821C12.5297 11.3088 12.7038 11.3797 12.8851 11.3797C13.0664 11.3797 13.2405 11.3088 13.3701 11.1821C13.4337 11.118 13.484 11.042 13.5181 10.9584C13.5522 10.8748 13.5695 10.7853 13.569 10.695C13.5697 10.6053 13.5524 10.5164 13.5183 10.4335C13.4841 10.3505 13.4338 10.2753 13.3701 10.212C13.2402 10.0844 13.0652 10.013 12.8831 10.0132V10.0091Z" fill={color} />
    <path d="M11.085 5.44695C10.7272 5.44521 10.3734 5.37257 10.0438 5.23324C9.7143 5.0939 9.41567 4.89063 9.16518 4.63518C8.65608 4.12597 8.37009 3.4354 8.37009 2.71536C8.37009 1.99531 8.65608 1.30474 9.16518 0.79553C9.48091 0.479718 9.86967 0.246654 10.297 0.116986C10.7243 -0.0126823 11.1771 -0.0349516 11.6151 0.0521508C12.0531 0.139253 12.4628 0.333038 12.808 0.616339C13.1532 0.899639 13.4232 1.26371 13.5941 1.67629C13.765 2.08888 13.8315 2.53724 13.7877 2.98166C13.7439 3.42609 13.5912 3.85285 13.343 4.22414C13.0949 4.59543 12.759 4.89979 12.3651 5.11025C11.9713 5.32071 11.5316 5.43078 11.085 5.43071V5.44695ZM11.085 2.04565C10.9496 2.04565 10.8172 2.08573 10.7045 2.16085C10.5918 2.23597 10.5039 2.34276 10.4518 2.46777C10.3997 2.59277 10.3858 2.7304 10.4118 2.8633C10.4378 2.9962 10.5026 3.11843 10.5979 3.21459C10.7266 3.34323 10.901 3.41549 11.083 3.41549C11.2649 3.41549 11.4394 3.34323 11.568 3.21459C11.6351 3.15295 11.6891 3.07841 11.7268 2.99542C11.7645 2.91243 11.785 2.8227 11.7872 2.73159C11.7867 2.54876 11.7137 2.37359 11.5842 2.24453C11.519 2.1791 11.441 2.1277 11.3552 2.0935C11.2693 2.0593 11.1774 2.04302 11.085 2.04565Z" fill={color} />
    <path d="M14.3199 10.8046C14.1055 10.8052 13.8965 10.7379 13.7227 10.6123C13.549 10.4868 13.4194 10.3094 13.3527 10.1057C13.286 9.90193 13.2855 9.6823 13.3514 9.47828C13.4172 9.27426 13.546 9.09633 13.7192 8.97002L19.2108 4.94367C19.4261 4.7822 19.6967 4.71287 19.9631 4.75093C20.2295 4.78898 20.4699 4.93132 20.6314 5.14661C20.7929 5.3619 20.8622 5.63252 20.8241 5.89893C20.7861 6.16535 20.6437 6.40573 20.4284 6.5672L14.9206 10.6057C14.7467 10.7344 14.5362 10.804 14.3199 10.8046Z" fill={color} />
    <path d="M12.5259 10.0538C12.2975 10.0527 12.076 9.97489 11.8972 9.83282C11.7183 9.69076 11.5924 9.4927 11.5396 9.27046L10.4518 4.62311C10.4214 4.49332 10.4169 4.35882 10.4385 4.22728C10.4601 4.09575 10.5074 3.96975 10.5777 3.85649C10.648 3.74324 10.7399 3.64493 10.8482 3.5672C10.9565 3.48946 11.079 3.43381 11.2088 3.40343C11.4709 3.34207 11.7467 3.38735 11.9754 3.52931C12.2041 3.67127 12.3671 3.89828 12.4285 4.1604L13.5162 8.80776C13.577 9.06987 13.5312 9.3454 13.3889 9.57375C13.2465 9.80209 13.0193 9.96455 12.7572 10.0254C12.6817 10.0449 12.6039 10.0544 12.5259 10.0538Z" fill={color} />
    <path d="M4.28243 6.53477C4.04677 6.53464 3.81851 6.45248 3.63682 6.30241C3.45513 6.15233 3.33133 5.9437 3.28669 5.71231C3.24204 5.48092 3.27933 5.2412 3.39214 5.0343C3.50496 4.8274 3.68627 4.66622 3.90496 4.57842L9.15302 2.48001C9.40143 2.38638 9.67663 2.39382 9.91961 2.50074C10.1626 2.60766 10.354 2.80555 10.4528 3.05195C10.5515 3.29835 10.5498 3.57365 10.448 3.8188C10.3461 4.06395 10.1523 4.25943 9.90796 4.3633L4.6599 6.46172C4.53983 6.50971 4.41174 6.5345 4.28243 6.53477Z" fill={color} />
  </svg>
);
