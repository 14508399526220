type EnergyIconProps = {
  color?: string;
  width?: string;
  height?: string;
}

export const EnergyIcon = ({ color, height, width }: EnergyIconProps): JSX.Element => (
  <svg width={width || '10'} height={height || '13x'} viewBox="0 0 10 13" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M4.38894 11.9308L4.38864 11.9308C4.32332 11.9307 4.25851 11.9194 4.19704 11.8974L4.19695 11.8973C4.08051 11.8554 3.98121 11.7761 3.91454 11.6719C3.84786 11.5676 3.81754 11.4442 3.82831 11.3209L3.82837 11.3203L4.13942 7.98764H1.4497H1.44769L1.44769 7.98762C1.34649 7.98559 1.24775 7.95611 1.162 7.90234C1.07624 7.84856 1.0067 7.77251 0.960795 7.68231C0.914888 7.5921 0.894338 7.49112 0.901339 7.39014C0.908336 7.28922 0.942596 7.19208 1.00046 7.1091H1.00046L1.00055 7.10898L5.15288 1.13931L5.15295 1.13921C5.22352 1.03802 5.32557 0.963026 5.44322 0.925905C5.56086 0.888784 5.68749 0.891627 5.80335 0.93399C5.91921 0.976353 6.0178 1.05586 6.08375 1.16011C6.14971 1.26436 6.17932 1.3875 6.16797 1.51034L6.16796 1.51045L5.85693 4.83763H8.5503H8.55231L8.55231 4.83765C8.65351 4.83968 8.75225 4.86916 8.838 4.92293C8.92376 4.97671 8.9933 5.05275 9.0392 5.14296C9.08511 5.23317 9.10566 5.33415 9.09866 5.43513C9.09167 5.53604 9.05741 5.63316 8.99956 5.71613L4.38894 11.9308ZM4.38894 11.9308C4.47885 11.9306 4.56739 11.9087 4.6471 11.8671C4.72681 11.8255 4.79535 11.7654 4.84693 11.6917L4.84714 11.6914L8.99945 5.71629L4.38894 11.9308ZM7.47927 5.95549L5.14064 9.3072L5.31248 7.48237C5.31249 7.48229 5.3125 7.48222 5.3125 7.48214M7.47927 5.95549L5.3125 7.48214M7.47927 5.95549H5.24427H5.24415V5.85549C5.18016 5.85557 5.11687 5.84221 5.05837 5.81628L7.47927 5.95549ZM5.3125 7.48214C5.31993 7.40481 5.31116 7.32678 5.28674 7.25302C5.26229 7.1792 5.22271 7.11129 5.17053 7.05363C5.11834 6.99598 5.0547 6.94985 4.98367 6.91819C4.91264 6.88653 4.83579 6.87004 4.75802 6.86978H4.75768H2.5207L4.85788 3.51945L4.68756 5.34432C4.68755 5.34444 4.68753 5.34457 4.68752 5.34469C4.67978 5.4221 4.68856 5.50028 4.71328 5.57405C4.73797 5.64776 4.77802 5.71538 4.83077 5.77247L5.3125 7.48214Z"
      stroke={color || '#636363'}
      strokeWidth="0.3"
      fill={color || '#636363'}
    />
  </svg>
);
