type OperatingCorrectlyIconProps = {
  color?: string;
}

export const OperatingCorrectlyIcon = ({ color = 'white', ...props }: OperatingCorrectlyIconProps): JSX.Element => (
  <svg {...props} width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M12 2C10.0222 2 8.08879 2.58649 6.4443 3.6853C4.79981 4.78412 3.51809 6.3459 2.76121 8.17317C2.00433 10.0004 1.8063 12.0111 2.19215 13.9509C2.578 15.8907 3.53041 17.6725 4.92894 19.0711C6.32746 20.4696 8.10929 21.422 10.0491 21.8079C11.9889 22.1937 13.9996 21.9957 15.8268 21.2388C17.6541 20.4819 19.2159 19.2002 20.3147 17.5557C21.4135 15.9112 22 13.9778 22 12C22 10.6868 21.7413 9.38642 21.2388 8.17317C20.7363 6.95991 19.9997 5.85752 19.0711 4.92893C18.1425 4.00035 17.0401 3.26375 15.8268 2.7612C14.6136 2.25866 13.3132 2 12 2ZM16.3 9.61L11.73 15.61C11.6368 15.731 11.5172 15.8291 11.3803 15.8967C11.2433 15.9643 11.0927 15.9996 10.94 16C10.7881 16.0008 10.638 15.967 10.5011 15.9012C10.3643 15.8353 10.2442 15.7392 10.15 15.62L7.71 12.51C7.62924 12.4063 7.5697 12.2876 7.53479 12.1609C7.49988 12.0341 7.49027 11.9017 7.50652 11.7713C7.52277 11.6408 7.56456 11.5148 7.6295 11.4005C7.69444 11.2862 7.78126 11.1858 7.885 11.105C8.09453 10.9419 8.36026 10.8687 8.62375 10.9015C8.75421 10.9178 8.8802 10.9596 8.99452 11.0245C9.10884 11.0894 9.20924 11.1763 9.29 11.28L10.92 13.36L14.7 8.36C14.7801 8.25494 14.8801 8.16669 14.9943 8.10029C15.1086 8.03388 15.2347 7.99062 15.3657 7.97298C15.4966 7.95534 15.6297 7.96365 15.7574 7.99746C15.8851 8.03126 16.0049 8.08989 16.11 8.17C16.2151 8.25011 16.3033 8.35012 16.3697 8.46433C16.4361 8.57855 16.4794 8.70472 16.497 8.83565C16.5147 8.96658 16.5063 9.0997 16.4725 9.22742C16.4387 9.35514 16.3801 9.47494 16.3 9.58V9.61Z"
      fill={color}
    />
  </svg>
);
