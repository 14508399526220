export const CloseBtn = (): JSX.Element => (
  <svg
    width="12"
    height="12"
    viewBox="0 0 12 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0.292893 0.292893C0.683417 -0.0976311 1.31658 -0.0976311 1.70711 0.292893L11.2071 9.79289C11.5976 10.1834 11.5976 10.8166 11.2071 11.2071C10.8166 11.5976 10.1834 11.5976 9.79289 11.2071L0.292893 1.70711C-0.0976311 1.31658 -0.0976311 0.683417 0.292893 0.292893Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.2071 0.292893C11.5976 0.683418 11.5976 1.31658 11.2071 1.70711L1.70711 11.2071C1.31658 11.5976 0.683417 11.5976 0.292893 11.2071C-0.0976319 10.8166 -0.0976319 10.1834 0.292893 9.79289L9.79289 0.292893C10.1834 -0.0976312 10.8166 -0.0976311 11.2071 0.292893Z"
      fill="currentColor"
    />
  </svg>
);
