import { Link } from 'react-router-dom';
import styled from 'styled-components';

import { colors } from '../../styles/colors';

export const Line = styled.div`
  margin-top: 10px;
  width: 95%;
  border: 0.5px solid rgba(0, 0, 0, 0.2);
`;

export const TopTitle = styled.div`
  font-family: Inter;
  font-style: normal;
  font-weight: bold;
  font-size: 13px;
  line-height: 16px;
  color: ${colors.Black};
`;

export const TopDate = styled.div`
  font-family: Inter;
  font-style: normal;
  font-weight: 600;
  font-size: 11px;
  line-height: 24px;
  text-align: right;
  letter-spacing: -0.5px;
  color: ${colors.GreyDark};
`;

export const ItemTitleTotal = styled.div`
  font-family: Inter;
  font-style: normal;
  font-weight: bold;
  font-size: 15px;
  color: ${colors.Black};
  word-break: normal;
`;

export const ItemTitle = styled.div`
  font-family: Inter;
  font-style: normal;
  font-weight: bold;
  font-size: 11px;
  color: ${colors.Black};
  word-break: normal;
`;

export const ItemSubTitle = styled.div`
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 9px;
  color: ${colors.Black};
  word-break: normal;
`;

export const ItemLegend = styled.div`
  font-family: Inter;
  font-style: normal;
  font-weight: 600;
  font-size: 9px;
  color: ${colors.Black};
  margin-top: 3px;
  word-break: normal;
`;

export const ItemValue = styled.div`
  display: flex;
  align-items: baseline;
  white-space: nowrap;
`;

export const ItemVal = styled.div`
  font-family: Inter;
  font-style: normal;
  font-weight: 700;
  font-size: 21px;
  letter-spacing: 0.5px;
  color: ${colors.Black};
`;

export const HealthGreat = styled.span`
  width: 15px;
  height: 5px;
  background: ${colors.GreenLight};
  border-radius: 3px;
  display: inline-grid;
  margin-left: 4px;
`;

export const HealthGood = styled.span`
  width: 15px;
  height: 5px;
  background: ${colors.Yellow_v3};
  border-radius: 3px;
  display: inline-grid;
  margin-left: 4px;
`;

export const HealthMedium = styled.span`
  width: 15px;
  height: 5px;
  background: ${colors.Orange_v3};
  border-radius: 3px;
  display: inline-grid;
  margin-left: 4px;
`;

export const HealthBad = styled.span`
  width: 15px;
  height: 5px;
  background: ${colors.RedDark};
  border-radius: 3px;
  display: inline-grid;
  margin-left: 4px;
`;

export const HealthDeactiv = styled.span`
  width: 15px;
  height: 5px;
  background: grey;
  border-radius: 3px;
  display: inline-grid;
  margin-left: 4px;
`;

export const HealthOthers = styled.span`
  width: 15px;
  height: 5px;
  background: ${colors.Grey_v3};
  border-radius: 3px;
  display: inline-grid;
  margin-left: 4px;
`;

export const BtnList = styled.div`
  border: 1px solid ${colors.BlueSecondary};
  box-sizing: border-box;
  border-radius: 3px;
  padding: 5px 10px;
  font-weight: bold;
  font-size: 9px;
  text-align: center;
  color: ${colors.BlueSecondary};
  cursor: pointer;
`;

export const ItemCompare = styled.div`
  font-style: normal;
  font-weight: normal;
  font-size: 9px;
  margin-left: 10px;
`;

export const RedTriangleUp = styled.div`
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 0 3px 6px 3px;
  border-color: transparent transparent ${colors.RedDark} transparent;
  margin-left: 3px;
`;

export const RedTriangleDown = styled.div`
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 6px 3px 0 3px;
  border-color: ${colors.RedDark} transparent transparent transparent;
  margin-left: 3px;
`;

export const GreenTriangleUp = styled.div`
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 0 3px 6px 3px;
  border-color: transparent transparent ${colors.GreenLight} transparent;
  margin-left: 3px;
`;

export const GreenTriangleDown = styled.div`
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 6px 3px 0 3px;
  border-color: ${colors.GreenLight} transparent transparent transparent;
  margin-left: 3px;
`;

export const LabelFilter = styled.div`
  font-weight: bold;
  font-size: 9px;
  color: ${colors.BlueSecondary};
`;

export const CellLabel = styled.span`
  color: ${colors.Black};
  font-weight: normal;
  font-size: 10px !important;
`;

export const StyledLink = styled(Link)`
  color: ${colors.Black};
  font-weight: normal;
  font-size: 10px !important;
`;

export const OverLay = styled.div`
  position: absolute;
  display: flex;
  background-color: #eceaea;
  width: 100%;
  height: 100%;
  z-index: 3;
  opacity: 0.4;
  filter: alpha(opacity=40);
  top: 0;
  left: 0;
`;

export const IconContainer = styled.div`
  width: 24px;
  height: 24px;
  display: flex;
  border-radius: 8px;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  & > svg {
    width: 18px;
  }
`;
