type CustomListProps = {
  color?: string;
  width?: string;
};

export const CustomListIcon = ({ width, color, ...props }: CustomListProps): JSX.Element => (
  <svg
    {...props}
    width={width || '27'}
    height="18"
    viewBox="0 0 27 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >

    <path fillRule="evenodd" clipRule="evenodd" d="M0 2H20V17.7576H0V2ZM1.21208 5.03027H9.09087V8.06058H1.21208V5.03027ZM9.09087 9.27279H1.21208V12.3031H9.09087V9.27279ZM1.21208 13.5153H9.09087V16.5456H1.21208V13.5153ZM18.7878 5.03027H10.909V8.06058H18.7878V5.03027ZM10.909 9.27279H18.7878V12.3031H10.909V9.27279ZM18.7878 13.5153H10.909V16.5456H18.7878V13.5153Z" fill={color || '#363BC4'} />
    <path d="M23.3874 7.85937L23.8575 7.38935L24.0946 7.15218L25.5116 5.73521L25.5117 5.73511C25.8244 5.42236 26 4.99824 26 4.55601C26 4.11378 25.8244 3.68965 25.5117 3.37691L25.5115 3.37667L23.6224 1.4883C23.6224 1.48829 23.6224 1.48828 23.6224 1.48828C23.3097 1.17563 22.8855 1 22.4433 1C22.0011 1 21.577 1.17564 21.2642 1.4883L21.2641 1.4884L19.8478 2.9047L19.6108 3.14171L19.1408 3.61172L18.9036 3.8489L12.2929 10.4596L12 10.7525V11.1667V14V15H13H15.8326H16.2468L16.5397 14.7071L23.1504 8.09638L23.3874 7.85937Z" fill={color || '#363BC4'} stroke="white" strokeWidth="2" />
  </svg>
);
