import { colors } from '../styles/colors';

type ArrowIconProps = {
  color?: string;
}

export const ArrowIcon = ({ color, ...props }: ArrowIconProps): JSX.Element => (
  <svg {...props} width="12" height="7" viewBox="0 0 12 7" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M6 6.49994C5.8684 6.5007 5.73793 6.47548 5.6161 6.42571C5.49426 6.37595 5.38344 6.30262 5.29 6.20994L1.29 2.20994C1.19676 2.1167 1.1228 2.00601 1.07234 1.88419C1.02188 1.76237 0.995911 1.6318 0.995911 1.49994C0.995911 1.36808 1.02188 1.23751 1.07234 1.11569C1.1228 0.993869 1.19676 0.883179 1.29 0.789941C1.38324 0.696702 1.49393 0.622741 1.61575 0.572281C1.73758 0.521821 1.86814 0.49585 2 0.49585C2.13186 0.49585 2.26243 0.521821 2.38425 0.572281C2.50607 0.622741 2.61676 0.696702 2.71 0.789941L6 4.09994L9.3 0.919941C9.39199 0.817651 9.5041 0.735442 9.62931 0.678456C9.75453 0.62147 9.89015 0.590933 10.0277 0.588754C10.1653 0.586574 10.3018 0.6128 10.4287 0.665791C10.5557 0.718782 10.6703 0.797398 10.7655 0.896723C10.8607 0.996047 10.9344 1.11394 10.9819 1.24304C11.0295 1.37213 11.0499 1.50965 11.0418 1.64698C11.0338 1.78432 10.9975 1.91852 10.9353 2.0412C10.873 2.16387 10.7861 2.27238 10.68 2.35994L6.68 6.21994C6.49713 6.39626 6.25401 6.49637 6 6.49994Z"
      fill={color || colors.Grey400}
    />
  </svg>
);
