type RemoteManualIconProps = {
    color?: string;
}

export const RemoteManualIcon = ({ color = '#363BC4' }: RemoteManualIconProps): JSX.Element => (
  <svg width="19" height="22" viewBox="0 0 19 22" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd" d="M9.5 2C9.09464 2 8.8 2.31705 8.8 2.66667V9.88889C8.8 10.4412 8.35229 10.8889 7.8 10.8889C7.24772 10.8889 6.8 10.4412 6.8 9.88889V3.77778C6.8 3.42816 6.50536 3.11111 6.1 3.11111C5.69464 3.11111 5.4 3.42816 5.4 3.77778V13.2222C5.4 13.7745 4.95228 14.2222 4.4 14.2222C3.84772 14.2222 3.4 13.7745 3.4 13.2222V10.4444C3.4 10.0948 3.10536 9.77778 2.7 9.77778C2.29464 9.77778 2 10.0948 2 10.4444V12.6667C2 16.6982 5.3391 20 9.5 20C13.6609 20 17 16.6982 17 12.6667V7.11111C17 6.7615 16.7054 6.44444 16.3 6.44444C15.8946 6.44444 15.6 6.7615 15.6 7.11111V9.88889C15.6 10.4412 15.1523 10.8889 14.6 10.8889C14.0477 10.8889 13.6 10.4412 13.6 9.88889V3.77778C13.6 3.42816 13.3054 3.11111 12.9 3.11111C12.4946 3.11111 12.2 3.42816 12.2 3.77778V9.88889C12.2 10.4412 11.7523 10.8889 11.2 10.8889C10.6477 10.8889 10.2 10.4412 10.2 9.88889V2.66667C10.2 2.31705 9.90536 2 9.5 2ZM11.8358 1.32694C11.365 0.528742 10.4876 0 9.5 0C8.51243 0 7.63499 0.528742 7.16422 1.32694C6.83639 1.18783 6.47618 1.11111 6.1 1.11111C4.62759 1.11111 3.4 2.28644 3.4 3.77778V7.86873C3.17613 7.80936 2.94135 7.77778 2.7 7.77778C1.22759 7.77778 0 8.95311 0 10.4444V12.6667C0 17.8399 4.27206 22 9.5 22C14.7279 22 19 17.8399 19 12.6667V7.11111C19 5.61978 17.7724 4.44444 16.3 4.44444C16.0586 4.44444 15.8239 4.47603 15.6 4.5354V3.77778C15.6 2.28644 14.3724 1.11111 12.9 1.11111C12.5238 1.11111 12.1636 1.18783 11.8358 1.32694Z" fill={color} />
  </svg>

);
