type WifiMiniOnIconProps = {
  color?: string;
  size?: number;
}

export const WifiMiniOnIcon = ({ color = '#363BC4', size }: WifiMiniOnIconProps): JSX.Element => (
  <svg width={size || '416'} height={size || '324'} viewBox="0 0 416 324" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M338.58 189.31C338.26 213.68 326.15 227.6 306.1 229.55C295.88 230.55 287.2 226.47 279.89 219.55C269.66 209.9 258.78 201.24 245.84 195.46C225.84 186.53 205.36 185.97 184.44 191.46C167.23 196 153.44 206.37 139.96 217.3C131.81 223.91 122.96 228.94 112.11 229.21C98.83 229.53 88.99 223.4 82.37 212.42C75.55 201.11 75.59 189.09 82.37 177.85C90.28 164.85 102.04 155.36 114.37 146.72C137.37 130.58 162.93 121.91 190.89 119.46C221.3 116.8 250.59 120.28 278.45 133.26C297.065 141.925 313.777 154.195 327.62 169.36C333.54 175.88 337.97 183.58 338.58 189.31Z" fill={color} />
    <path d="M207.98 323.59C184.77 323.46 166.6 304.94 166.56 281.35C166.52 257.76 184.86 239.14 207.95 239.15C231.29 239.15 249.45 257.91 249.3 281.79C249.14 304.88 230.54 323.71 207.98 323.59Z" fill={color} />
    <path d="M406.14 85.48C382.733 59.5495 353.862 39.1385 321.61 25.72C294.961 14.4299 266.906 6.79738 238.21 3.02998C228.21 1.73998 218.04 1.47999 207.93 0.899994C197.82 1.47999 187.67 1.73998 177.65 3.02998C148.944 6.79377 120.879 14.4264 94.2201 25.72C61.9643 39.1372 33.0896 59.5483 9.68006 85.48C-8.07994 105.11 -0.129904 133.34 25.0901 141.41C40.3701 146.3 52.3701 140.29 63.2001 130.29C78.0501 116.56 94.03 104.42 111.91 94.84C141.467 79.1206 174.433 70.8998 207.91 70.8998C241.387 70.8998 274.353 79.1206 303.91 94.84C321.79 104.42 337.77 116.56 352.62 130.29C363.45 140.29 375.45 146.29 390.73 141.41C415.96 133.34 423.9 105.11 406.14 85.48Z" fill={color} />
  </svg>
);
