import { colors } from '../styles/colors';

type ButtonPowerIconProps = {
  color?: string;
  width?: string;
}

export const ButtonPowerIcon = ({ width = '160px', color = colors.Grey400, ...props }: ButtonPowerIconProps): JSX.Element => (
  <svg {...props} width={width} height={width} viewBox="0 0 180 196" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M98.1633 81.6676C98.1633 86.1779 94.507 89.8342 89.9967 89.8342C85.4863 89.8342 81.8301 86.1775 81.8301 81.6672V8.1666C81.8301 3.65627 85.4863 0 89.9967 0C94.507 0 98.1637 3.65627 98.1633 8.16699V81.6676ZM115.175 28.024C116.981 23.8908 121.795 22.0039 125.928 23.8096C158.397 37.9941 179.834 70.1161 179.834 106.166C179.834 155.778 139.61 196 90.0002 196C40.385 196 0.166016 155.781 0.166016 106.166C0.166016 70.1245 21.5941 38.004 54.0527 23.8111C58.1852 22.0039 63.0002 23.8892 64.8071 28.0217C66.6144 32.1542 64.729 36.9693 60.5965 38.7762C34.0322 50.3923 16.4992 76.6737 16.4992 106.166C16.4992 146.761 49.4053 179.667 89.9998 179.667C130.589 179.667 163.5 146.758 163.5 106.166C163.5 76.6672 145.961 50.3858 119.389 38.7773C115.256 36.9716 113.369 32.1573 115.175 28.024Z"
      fill={color}
    />
  </svg>
);
