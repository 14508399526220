export const DeleteTrashIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="17"
    height="18"
    fill="none"
    viewBox="0 0 17 18"
  >
    <g>
      <g>
        <g fill="#E00030" stroke="#E00030" strokeWidth="0.2">
          <path d="M10.563 17.1h0a2.798 2.798 0 001.882-.751c.512-.477.83-1.13.893-1.83h0l.82-9.844.008-.1-.1-.008-1.344-.114-.1-.009-.008.1-.807 9.844s0 0 0 0a1.276 1.276 0 01-.402.82c-.23.214-.53.333-.843.336H6.44h0a1.239 1.239 0 01-.847-.331 1.26 1.26 0 01-.398-.824h0l-.82-9.845-.008-.1-.1.009-1.345.114-.1.008.01.1.814 9.844h0c.062.703.383 1.357.898 1.835.516.477 1.19.743 1.89.746h4.129z" />
          <path d="M1.672 5.387h13.656a.77.77 0 00.546-.228.781.781 0 000-1.1.77.77 0 00-.546-.227H1.672a.77.77 0 00-.546.228.78.78 0 00.546 1.327z" />
          <path d="M12.08 5.387h.1V4.61c0-.983-.388-1.926-1.078-2.622A3.664 3.664 0 008.5.9c-.976 0-1.912.391-2.602 1.087a3.725 3.725 0 00-1.077 2.622v.778H6.365V4.61c.008-.57.235-1.113.634-1.515a2.155 2.155 0 011.502-.64c.565.002 1.107.229 1.507.632.4.404.626.951.627 1.523v.777H12.079z" />
          <path d="M9.756 13.41h0l-.404-4.73-.1-.007h0s0 0 0 0l-.268 3.908h0a.783.783 0 00.428.75c.091.044.19.071.291.078h.053z" />
          <path d="M7.244 13.41v-.001a.769.769 0 01-.527-.211.78.78 0 01-.242-.517s0 0 0 0l-.269-3.907h0a.783.783 0 01.19-.565.772.772 0 011.354.461h0l-.1.007.1-.006-.506 4.738zm0 0l.006-.001.049-.003h0a.766.766 0 00.531-.264l-.075-.066-.51.333z" />
        </g>
      </g>
    </g>
  </svg>
);
