interface OutHealthIconProps {
  color?: string;
  height?: string;
  width?: string;
}

export const OutHealthIcon = ({ color, height, width }: OutHealthIconProps): JSX.Element => (
  <svg width={width || '23'} height={height || '23'} viewBox="0 0 23 23" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M8.46994 2.38869C9.86136 -0.263024 13.3399 -0.263027 14.7313 2.38869L22.3109 16.8335C23.7023 19.4853 21.9631 22.7999 19.1802 22.7999H4.02105C1.23821 22.7999 -0.501073 19.4853 0.890348 16.8335L8.46994 2.38869Z" fill={color || '#FFBE16'} />
    <path d="M13.9385 6L13.5846 15.5782H10.4231L10.0692 6H13.9385ZM12 20C11.4513 20 10.9795 19.8292 10.5846 19.4877C10.1949 19.1462 10 18.7328 10 18.2475C10 17.7711 10.1949 17.3645 10.5846 17.0274C10.9795 16.6859 11.4513 16.5152 12 16.5152C12.5282 16.5152 12.9923 16.6859 13.3923 17.0274C13.7974 17.3645 14 17.7711 14 18.2475C14 18.571 13.9051 18.8654 13.7154 19.1305C13.5308 19.3956 13.2872 19.6068 12.9846 19.7641C12.6872 19.9214 12.359 20 12 20Z" fill="white" />
  </svg>

);
