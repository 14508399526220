export const colors = {
  Black: '#000',
  White: '#fff',
  A350: '#383FB8',
  Grey030: '#F8F8F8',
  Grey050: '#E9E7E9',
  Grey100: '#74747433',
  Grey200: '#ABB0B4',
  Grey300: '#77818B',
  Grey400: '#464555',
  Grey500: '#5B5B5B',
  GreyInputBorder: '#818181',
  GreyInputPlaceholder: '#666666',
  GreyDefaultCardBorder: '#D7D7D7',
  Pink100: '#FF5473',

  Pink200: '#ED193F',

  Pink300: '#D60E32',
  Pink400: '#B90B2E',
  Blue100: '#A8ADFF',
  Blue200: '#5C64FF',
  Blue300: '#363BC4',
  Blue400: '#2D3181',
  Blue500: '#181842',
  Blue600: '#0A0A2B',
  Blue700: '#202370',
  BlueSecondary: '#363BC4',
  BlueChart: '#3B42BA',

  GrenTab: '#00FF9B',
  BlueMenu: '#0F0F36',

  Red: '#DC0E01',
  Orange: '#FF5C00',
  LightOrange: '#FFBE16',
  Yellow: '#FDB400',
  Green: '#58CC00',
  LightBlue: '#4C87C7',
  MediumBlue: '#2A67A9',
  DarkBlue: '#171743',
  DarkGrey: '#5D5D5D',
  VeryDarkGrey: '#404040',
  DarkGrey70: 'rgba(93, 93, 93, 0.7)',
  Grey: '#DBDBDB',
  GreyDark: '#3C3C3C',
  GreyLight: '#E9E9E9',
  MediumGrey: '#bfbfbf',
  LightGrey: '#F5F5F5',
  Red_v2: '#D94438', // f44336
  LightRed: '#eee0de',
  VeryLightBlue: '#e0e5ea',
  Green_v2: '#299656',
  Yellow_v2: '#EACB3E',
  Orange600: '#be5f0f',
  Orange_v2: '#E5954D',
  RedDark: '#E00030',
  GreenLight: '#5AB365',
  Yellow_v3: '#F8D000',
  Orange_v3: '#FF4D00',
  Grey_v3: '#BBBBBB',
  LightGrey_v3: '#C4C4C4',
  LightLightGrey_v3: '#ECECEC',
  BlueSecondary_v3: '#2D81FF',
  BlueSelectedSpan: '#BEC0FF',
};
