type PortIconProps = {
  color?: string;
  width?: string;
  height?: string;
}

export const PortIcon = ({
  color = '#363BC4', width = '14', height = '15', ...props
}: PortIconProps): JSX.Element => (
  <svg width={width} height={height} viewBox="0 0 14 15" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M10.3333 13H10.2C9.0799 13 8.51984 13 8.09202 12.782C7.71569 12.5903 7.40973 12.2843 7.21799 11.908C7 11.4802 7 10.9201 7 9.8V5.53333C7 4.41323 7 3.85318 7.21799 3.42535C7.40973 3.04903 7.71569 2.74307 8.09202 2.55132C8.51984 2.33333 9.0799 2.33333 10.2 2.33333H10.3333M10.3333 13C10.3333 13.7364 10.9303 14.3333 11.6667 14.3333C12.403 14.3333 13 13.7364 13 13C13 12.2636 12.403 11.6667 11.6667 11.6667C10.9303 11.6667 10.3333 12.2636 10.3333 13ZM10.3333 2.33333C10.3333 3.06971 10.9303 3.66667 11.6667 3.66667C12.403 3.66667 13 3.06971 13 2.33333C13 1.59695 12.403 1 11.6667 1C10.9303 1 10.3333 1.59695 10.3333 2.33333ZM3.66667 7.66667L10.3333 7.66667M3.66667 7.66667C3.66667 8.40305 3.06971 9 2.33333 9C1.59695 9 1 8.40305 1 7.66667C1 6.93029 1.59695 6.33333 2.33333 6.33333C3.06971 6.33333 3.66667 6.93029 3.66667 7.66667ZM10.3333 7.66667C10.3333 8.40305 10.9303 9 11.6667 9C12.403 9 13 8.40305 13 7.66667C13 6.93029 12.403 6.33333 11.6667 6.33333C10.9303 6.33333 10.3333 6.93029 10.3333 7.66667Z" stroke={color} strokeWidth="1.3" strokeLinecap="round" strokeLinejoin="round" />
  </svg>

);
