type FilterIconProps = {
  filtered?: boolean;
};

export const FilterIcon = ({
  filtered,
}: FilterIconProps): JSX.Element => (
  <svg
    width="11"
    height="10"
    viewBox="0 0 11 10"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      opacity="1"
      d="M1.50217 2.16498C1.17177 1.79572 1.00658 1.61108 1.00034 1.45417C0.994932 1.31786 1.05351 1.18684 1.1587 1.09999C1.2798 1 1.52755 1 2.02305 1H8.50695C9.00245 1 9.2502 1 9.3713 1.09999C9.47649 1.18684 9.53507 1.31786 9.52966 1.45417C9.52342 1.61108 9.35823 1.79572 9.02783 2.16498L6.53515 4.95092C6.46929 5.02453 6.43636 5.06134 6.41288 5.10322C6.39205 5.14037 6.37677 5.18037 6.36752 5.22194C6.35709 5.26881 6.35709 5.3182 6.35709 5.41697V7.75275C6.35709 7.83818 6.35709 7.8809 6.34331 7.91783C6.33113 7.95046 6.31133 7.97971 6.28556 8.00313C6.25639 8.02964 6.21673 8.0455 6.13741 8.07723L4.65217 8.67132C4.49161 8.73554 4.41134 8.76766 4.34689 8.75426C4.29054 8.74255 4.24108 8.70907 4.20928 8.6611C4.17291 8.60624 4.17291 8.51977 4.17291 8.34685V5.41697C4.17291 5.3182 4.17291 5.26881 4.16248 5.22194C4.15323 5.18037 4.13795 5.14037 4.11713 5.10322C4.09364 5.06134 4.06071 5.02453 3.99485 4.95092L1.50217 2.16498Z"
      stroke={filtered ? '#363BC4' : '#696969'}
      strokeWidth="1.3"
      strokeLinecap="round"
      strokeLinejoin="round"
      fill={filtered ? '#363BC4' : 'none'}
    />
  </svg>
);
