type OpenEyeIconProps = {
    color?: string;
  }

export const OpenEyeIcon = ({ color = '#202370', ...props }: OpenEyeIconProps): JSX.Element => (
  <svg {...props} width="21" height="14" viewBox="0 0 21 14" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M10.0304 13.103C5.84398 13.103 2.32697 9.96315 0.60415 8.09081C0.215803 7.67228 0 7.12243 0 6.55149C0 5.98054 0.215803 5.43069 0.60415 5.01216C2.32697 3.13982 5.84398 0 10.0304 0C14.2168 0 17.7644 3.167 19.494 5.05633C19.8697 5.46363 20.0783 5.99741 20.0783 6.55149C20.0783 7.10556 19.8697 7.63934 19.494 8.04664C17.7644 9.93597 14.2406 13.103 10.0304 13.103ZM1.85464 6.1641C1.75646 6.2692 1.70185 6.40766 1.70185 6.55149C1.70185 6.69531 1.75646 6.83377 1.85464 6.93887C3.09154 8.2811 6.36049 11.4039 10.0304 11.4039C13.7003 11.4039 16.9897 8.25392 18.2402 6.89809C18.3271 6.80291 18.3753 6.67868 18.3753 6.54979C18.3753 6.42089 18.3271 6.29666 18.2402 6.20148C16.9897 4.84905 13.7207 1.69904 10.0304 1.69904C6.3401 1.69904 3.09154 4.82187 1.85464 6.1641Z"
      fill={color}
    />
    <path
      d="M10.0304 9.53839C9.43961 9.53839 8.86212 9.36321 8.37093 9.035C7.87973 8.7068 7.4969 8.24031 7.27082 7.69452C7.04475 7.14874 6.9856 6.54817 7.10085 5.96877C7.2161 5.38936 7.50058 4.85715 7.9183 4.43942C8.33603 4.02169 8.86825 3.73722 9.44765 3.62197C10.0271 3.50672 10.6276 3.56587 11.1734 3.79194C11.7192 4.01801 12.1857 4.40085 12.5139 4.89205C12.8421 5.38324 13.0173 5.96073 13.0173 6.55148C13.0164 7.34338 12.7014 8.10259 12.1414 8.66255C11.5815 9.22251 10.8223 9.53749 10.0304 9.53839ZM10.0304 5.26361C9.77565 5.26361 9.52665 5.33915 9.31486 5.48066C9.10307 5.62217 8.938 5.82331 8.84053 6.05864C8.74305 6.29396 8.71755 6.55291 8.76724 6.80273C8.81693 7.05256 8.93959 7.28203 9.1197 7.46214C9.29981 7.64226 9.52929 7.76491 9.77911 7.81461C10.0289 7.8643 10.2879 7.8388 10.5232 7.74132C10.7585 7.64384 10.9597 7.47877 11.1012 7.26698C11.2427 7.0552 11.3182 6.8062 11.3182 6.55148C11.3173 6.21019 11.1814 5.88314 10.94 5.64181C10.6987 5.40048 10.3717 5.26451 10.0304 5.26361Z"
      fill={color}
    />
  </svg>
);
