type ThermostatSquareIconProps = {
  color?: string;
}

export const ThermostatSquareIcon = ({ color = '#363BC4' }: ThermostatSquareIconProps): JSX.Element => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M1 5C1 3.59987 1 2.8998 1.27248 2.36502C1.51217 1.89462 1.89462 1.51217 2.36502 1.27248C2.8998 1 3.59987 1 5 1H12C13.4001 1 14.1002 1 14.635 1.27248C15.1054 1.51217 15.4878 1.89462 15.7275 2.36502C16 2.8998 16 3.59987 16 5V12C16 13.4001 16 14.1002 15.7275 14.635C15.4878 15.1054 15.1054 15.4878 14.635 15.7275C14.1002 16 13.4001 16 12 16H5C3.59987 16 2.8998 16 2.36502 15.7275C1.89462 15.4878 1.51217 15.1054 1.27248 14.635C1 14.1002 1 13.4001 1 12V5Z" stroke={color} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M5.16667 6.5C5.16667 6.03329 5.16667 5.79993 5.25749 5.62167C5.33739 5.46487 5.46487 5.33739 5.62167 5.25749C5.79993 5.16667 6.03329 5.16667 6.5 5.16667H10.5C10.9667 5.16667 11.2001 5.16667 11.3783 5.25749C11.5351 5.33739 11.6626 5.46487 11.7425 5.62167C11.8333 5.79993 11.8333 6.03329 11.8333 6.5V10.5C11.8333 10.9667 11.8333 11.2001 11.7425 11.3783C11.6626 11.5351 11.5351 11.6626 11.3783 11.7425C11.2001 11.8333 10.9667 11.8333 10.5 11.8333H6.5C6.03329 11.8333 5.79993 11.8333 5.62167 11.7425C5.46487 11.6626 5.33739 11.5351 5.25749 11.3783C5.16667 11.2001 5.16667 10.9667 5.16667 10.5V6.5Z" stroke={color} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
  </svg>

);
