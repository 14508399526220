type LockIconProps = {
  color?: string;
}

export const LockOpenedIcon = ({ color, ...props }: LockIconProps): JSX.Element => (
  <svg {...props} width="15" height="16" viewBox="0 0 15 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M5.15149 6.83849C6.54196 6.83849 7.93258 6.83849 9.32336 6.83849C9.92851 6.83849 10.2272 7.10574 10.2784 7.68852C10.2871 7.78932 10.2856 7.89153 10.2856 7.9928C10.2856 10.2655 10.2856 12.538 10.2856 14.8104C10.2856 14.8572 10.2856 14.9041 10.2856 14.951C10.2774 15.7096 9.97773 15.9994 9.20127 15.9994H1.05057C0.285202 15.9994 0 15.7227 0 14.982C0 12.5752 0 10.1698 0 7.76588C0 7.12496 0.295336 6.83849 0.958878 6.83802C2.35674 6.8374 3.75428 6.83755 5.15149 6.83849Z" fill={color || '#202370'} />
    <path d="M14.4852 4.77725C14.4852 5.2958 14.4794 5.7023 14.4876 6.10832C14.4915 6.3104 14.4288 6.40979 14.1981 6.40089C13.8284 6.38729 13.4583 6.3901 13.0882 6.40089C12.8865 6.40604 12.8242 6.32587 12.8261 6.13739C12.8334 5.36379 12.831 4.59018 12.8261 3.81752C12.8232 3.0697 12.5501 2.45082 11.915 1.9965C10.6536 1.09068 8.75802 1.86382 8.56499 3.37586C8.45497 4.24605 8.53942 5.12374 8.52204 5.99767C8.51432 6.3962 8.52204 6.39667 8.11523 6.3976C7.77743 6.3976 7.43963 6.39526 7.10182 6.3976C6.97394 6.3976 6.87067 6.36713 6.87308 6.22225C6.8919 5.15374 6.75775 4.07726 6.94885 3.0186C7.28183 1.17132 8.98484 -0.0964486 10.9301 0.00576082C12.7755 0.102344 14.2893 1.55859 14.4592 3.39133C14.5055 3.89113 14.4664 4.39045 14.4852 4.77725Z" fill={color || '#202370'} />
  </svg>
);

export const LockClosedIcon = ({ color, ...props }: LockIconProps): JSX.Element => (
  <svg {...props} width="11" height="16" viewBox="0 0 11 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M5.14233 6.83732C6.54179 6.83732 7.94126 6.83732 9.34073 6.83732C9.94685 6.83732 10.2364 7.11863 10.2653 7.70329C10.2967 8.3353 10.2653 8.96918 10.2653 9.59838C10.2653 11.3406 10.2789 13.0829 10.2851 14.8251C10.2857 14.9661 10.2783 15.1069 10.2629 15.2471C10.2147 15.6897 9.91017 15.9687 9.45414 15.9973C9.38175 16.0015 9.30937 15.9996 9.23698 15.9996H1.08145C0.283272 15.9996 0 15.7272 0 14.9611C0 12.57 0 10.1788 0 7.78768C0 7.13129 0.298714 6.83873 0.965151 6.83826C2.35979 6.83669 3.75219 6.83638 5.14233 6.83732Z" fill={color || '#202370'} />
    <path d="M1.3506 4.66701C1.38342 4.1208 1.29125 3.47191 1.44712 2.82912C1.87082 1.10516 3.47925 -0.0660333 5.34971 0.00288772C7.08409 0.0666514 8.63122 1.42304 8.8923 3.11652C8.99412 3.7762 8.93283 4.44243 8.94442 5.10539C8.95069 5.45703 8.94055 5.80867 8.94683 6.1603C8.94972 6.32862 8.88361 6.40082 8.70554 6.39801C8.31948 6.39145 7.93342 6.3891 7.54736 6.39801C7.34612 6.40317 7.28194 6.32487 7.28339 6.13499C7.29015 5.33044 7.29015 4.52573 7.28339 3.72087C7.2747 2.27775 5.91239 1.2941 4.49459 1.70482C4.07231 1.8295 3.70138 2.0806 3.43472 2.42227C3.16806 2.76394 3.01931 3.17872 3.0097 3.60741C2.9986 4.42743 3.0068 5.24839 3.00487 6.06841C3.00487 6.38254 2.98943 6.39661 2.66176 6.39661C2.29983 6.39661 1.9379 6.39239 1.57597 6.39661C1.41093 6.39661 1.34674 6.33237 1.34867 6.17296C1.35398 5.70411 1.3506 5.23526 1.3506 4.66701Z" fill={color || '#202370'} />
  </svg>

);
