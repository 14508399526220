type EditIconProps = {
  color?: string;
}

export const EditIconPaperPen = ({ color }: EditIconProps): JSX.Element => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="18"
    height="18"
    fill="none"
    viewBox="0 0 18 18"
  >
    <path
      stroke="#363BC4"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
      d="M8.607 2.594H5.45c-1.263 0-1.894 0-2.376.246a2.254 2.254 0 00-.986.985c-.245.482-.245 1.113-.245 2.376v6.312c0 1.263 0 1.894.245 2.376.217.424.561.77.986.985.482.246 1.113.246 2.376.246h6.312c1.262 0 1.894 0 2.376-.246.424-.216.769-.56.985-.985.246-.482.246-1.113.246-2.376V9.357m-9.018 2.254h1.259c.367 0 .551 0 .724-.041.153-.037.3-.098.434-.18.152-.093.282-.223.542-.483l7.186-7.186a1.594 1.594 0 10-2.255-2.254L7.058 8.653c-.26.26-.39.39-.483.541a1.502 1.502 0 00-.18.435c-.042.173-.042.356-.042.724v1.258z"
    />
  </svg>
);
