import { colors } from '../styles/colors';

type EditIconProps = {
  color?: string;
  variant?: string;
  width?: string;
}

export const EditIcon = ({
  variant, width = '24px', color = colors.Grey400, ...props
}: EditIconProps): JSX.Element => {
  switch (variant) {
    case 'secondary':
      return (
        <svg {...props} width={width} height={width} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M19 20H5C4.73478 20 4.48043 20.1054 4.29289 20.2929C4.10536 20.4804 4 20.7348 4 21C4 21.2652 4.10536 21.5196 4.29289 21.7071C4.48043 21.8946 4.73478 22 5 22H19C19.2652 22 19.5196 21.8946 19.7071 21.7071C19.8946 21.5196 20 21.2652 20 21C20 20.7348 19.8946 20.4804 19.7071 20.2929C19.5196 20.1054 19.2652 20 19 20Z"
            fill="white"
          />
          <path
            d="M5.00005 17.9999H5.09005L9.26005 17.6199C9.71685 17.5744 10.1441 17.3731 10.4701 17.0499L19.4701 8.04986C19.8194 7.68083 20.0082 7.18837 19.995 6.68039C19.9819 6.17242 19.768 5.69037 19.4001 5.33986L16.6601 2.59986C16.3024 2.26395 15.8338 2.07122 15.3434 2.05831C14.8529 2.0454 14.3748 2.21323 14.0001 2.52986L5.00005 11.5299C4.67682 11.8558 4.47556 12.2831 4.43005 12.7399L4.00005 16.9099C3.98658 17.0563 4.00559 17.204 4.05571 17.3422C4.10584 17.4805 4.18585 17.606 4.29005 17.7099C4.38349 17.8025 4.49431 17.8759 4.61615 17.9256C4.73798 17.9754 4.86845 18.0006 5.00005 17.9999ZM15.2701 3.99986L18.0001 6.72986L16.0001 8.67986L13.3201 5.99986L15.2701 3.99986Z"
            fill="white"
          />
        </svg>
      );
    case 'note':
      return (
        <svg {...props} width={width} height={width} viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M15.6186 18.9999H3.38134C2.48481 18.999 1.62525 18.6425 0.991312 18.0086C0.357373 17.3746 0.000852616 16.5151 0 15.6186L0 3.38134C0.000852616 2.48481 0.357373 1.62525 0.991312 0.991312C1.62525 0.357373 2.48481 0.000852616 3.38134 0L9.49995 0C9.71347 0 9.91824 0.0848207 10.0692 0.235803C10.2202 0.386784 10.305 0.59156 10.305 0.80508C10.305 1.0186 10.2202 1.22338 10.0692 1.37436C9.91824 1.52534 9.71347 1.61016 9.49995 1.61016H3.38134C2.91185 1.61101 2.46184 1.79789 2.12987 2.12987C1.79789 2.46184 1.61101 2.91185 1.61016 3.38134V15.6186C1.61101 16.088 1.79789 16.538 2.12987 16.87C2.46184 17.202 2.91185 17.3889 3.38134 17.3897H15.6186C16.088 17.3889 16.538 17.202 16.87 16.87C17.202 16.538 17.3889 16.088 17.3897 15.6186V9.49995C17.3897 9.28643 17.4746 9.08165 17.6255 8.93067C17.7765 8.77969 17.9813 8.69487 18.1948 8.69487C18.4083 8.69487 18.6131 8.77969 18.7641 8.93067C18.9151 9.08165 18.9999 9.28643 18.9999 9.49995V15.6186C18.999 16.5151 18.6425 17.3746 18.0086 18.0086C17.3746 18.6425 16.5151 18.999 15.6186 18.9999Z" fill={color} />
          <path d="M5.50996 14.295C5.38701 14.2954 5.2656 14.2677 5.15505 14.2139C5.0445 14.16 4.94774 14.0816 4.87219 13.9846C4.79664 13.8876 4.74431 13.7746 4.71922 13.6543C4.69412 13.5339 4.69693 13.4094 4.72742 13.2903L5.65487 9.67708C5.69018 9.5371 5.76248 9.40919 5.8642 9.30674L14.9358 0.2351C15.0877 0.0852427 15.2925 0.0012207 15.5058 0.0012207C15.7192 0.0012207 15.924 0.0852427 16.0758 0.2351L18.7648 2.92407C18.9154 3.07552 19 3.28045 19 3.49406C19 3.70768 18.9154 3.9126 18.7648 4.06406L9.69316 13.1357C9.59071 13.2374 9.4628 13.3097 9.32282 13.345L5.70962 14.2693C5.64459 14.2871 5.57739 14.2958 5.50996 14.295ZM7.15876 10.305L6.62419 12.3886L8.71096 11.8572L17.0677 3.49406L15.5058 1.93221L7.15876 10.305Z" fill={color} />
          <path d="M15.8859 6.6081C15.7803 6.60816 15.6758 6.5871 15.5784 6.54618C15.4811 6.50525 15.3929 6.44529 15.3191 6.3698L12.6301 3.68083C12.493 3.52745 12.4199 3.32741 12.4256 3.12176C12.4314 2.91612 12.5156 2.72048 12.6611 2.57501C12.8066 2.42954 13.0022 2.34527 13.2079 2.33952C13.4135 2.33376 13.6135 2.40695 13.7669 2.54406L16.4559 5.23303C16.5672 5.34621 16.6427 5.48968 16.6731 5.64552C16.7034 5.80137 16.6871 5.96268 16.6263 6.10935C16.5655 6.25601 16.4629 6.38152 16.3312 6.47022C16.1995 6.55891 16.0447 6.60687 15.8859 6.6081Z" fill={color} />
        </svg>
      );
    default:
      return (
        <svg {...props} width={width} height={width} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M19 20H5C4.73478 20 4.48043 20.1054 4.29289 20.2929C4.10536 20.4804 4 20.7348 4 21C4 21.2652 4.10536 21.5196 4.29289 21.7071C4.48043 21.8946 4.73478 22 5 22H19C19.2652 22 19.5196 21.8946 19.7071 21.7071C19.8946 21.5196 20 21.2652 20 21C20 20.7348 19.8946 20.4804 19.7071 20.2929C19.5196 20.1054 19.2652 20 19 20Z"
            fill={color}
          />
          <path
            d="M5.00005 18H5.09005L9.26005 17.62C9.71685 17.5745 10.1441 17.3732 10.4701 17.05L19.4701 8.04998C19.8194 7.68095 20.0082 7.18849 19.995 6.68052C19.9819 6.17254 19.768 5.69049 19.4001 5.33998L16.6601 2.59998C16.3024 2.26408 15.8338 2.07134 15.3434 2.05843C14.8529 2.04553 14.3748 2.21335 14.0001 2.52998L5.00005 11.53C4.67682 11.8559 4.47556 12.2832 4.43005 12.74L4.00005 16.91C3.98658 17.0564 4.00559 17.2041 4.05571 17.3424C4.10584 17.4806 4.18585 17.6062 4.29005 17.71C4.38349 17.8027 4.49431 17.876 4.61615 17.9258C4.73798 17.9755 4.86845 18.0007 5.00005 18ZM15.2701 3.99998L18.0001 6.72998L16.0001 8.67998L13.3201 5.99998L15.2701 3.99998Z"
            fill={color}
          />
        </svg>
      );
  }
};
