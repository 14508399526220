type UnknownHealthIconProps = {
  color?: string;
}

export const UnknownHealthIcon = ({ color = '#fff', ...props }: UnknownHealthIconProps): JSX.Element => (
  <svg {...props} width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fill={color}
      d="m 4.4908102,0.25339911 c -2.239329,0 -4.04101602,1.80168699 -4.04101602,4.04101599 V 19.563946 c 0,2.239329 1.80168702,4.042969 4.04101602,4.042969 H 19.705654 c 2.239329,0 4.041015,-1.80364 4.041015,-4.042969 V 4.2944151 c 0,-2.239329 -1.801686,-4.04101599 -4.041015,-4.04101599 z M 11.754482,3.7612111 c 1.582055,0 2.810478,0.383704 3.683593,1.152344 0.873116,0.761178 1.308594,1.840836 1.308594,3.236328 0,0.634315 -0.142205,1.23363 -0.425781,1.800782 -0.283576,0.5596889 -0.779342,1.1845409 -1.488281,1.8710939 l -0.90625,0.861328 c -0.567152,0.544764 -0.892522,1.182735 -0.97461,1.914062 l -0.04492,0.681641 h -2.865234 c 0,-1.044754 0.127133,-1.876704 0.38086,-2.496094 0.253725,-0.61939 0.717044,-1.227217 1.388671,-1.824219 0.67909,-0.604464 1.129641,-1.0936699 1.353516,-1.4667969 0.223876,-0.380588 0.335937,-0.779364 0.335937,-1.197265 0,-1.261167 -0.581939,-1.890625 -1.746093,-1.890625 -0.552227,0 -0.996218,0.170396 -1.332031,0.513671 -0.328352,0.335815 -0.5006998,0.803389 -0.5156258,1.400391 h -3.246093 c 0.014926,-1.425343 0.473989,-2.541703 1.376953,-3.347656 0.910428,-0.805953 2.1496668,-1.208985 3.7167968,-1.208985 z M 11.529872,16.99168 c 0.52984,0 0.963015,0.16579 1.298829,0.494141 0.343275,0.320889 0.515624,0.73048 0.515624,1.230469 1e-6,0.492526 -0.168092,0.899814 -0.503906,1.220703 -0.328351,0.320888 -0.765782,0.480469 -1.310547,0.480469 -0.544764,0 -0.984499,-0.159581 -1.320312,-0.480469 -0.3283508,-0.320889 -0.4921878,-0.728177 -0.4921878,-1.220703 0,-0.499989 0.168093,-0.90958 0.5039068,-1.230469 0.343276,-0.328351 0.778755,-0.494141 1.308593,-0.494141 z"
    />
  </svg>
);
