type CalendarPlusIconProps = {
  color?: string;
}

export const CalendarPlusIcon = ({ color }: CalendarPlusIconProps): JSX.Element => (
  <svg width="16" height="19" viewBox="0 0 16 19" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M11.4299 3.70584C11.2356 3.70584 11.0493 3.6245 10.9119 3.47971C10.7745 3.33493 10.6973 3.13855 10.6973 2.93379V0.77205C10.6973 0.56729 10.7745 0.370916 10.9119 0.226128C11.0493 0.0813408 11.2356 0 11.4299 0C11.6243 0 11.8106 0.0813408 11.948 0.226128C12.0854 0.370916 12.1626 0.56729 12.1626 0.77205V2.93379C12.1626 3.03518 12.1437 3.13557 12.1069 3.22924C12.07 3.32291 12.0161 3.40802 11.948 3.47971C11.88 3.55141 11.7992 3.60827 11.7103 3.64707C11.6214 3.68587 11.5262 3.70584 11.4299 3.70584Z" fill="#373737" />
    <path d="M4.10304 3.70584C3.90872 3.70584 3.72236 3.6245 3.58496 3.47971C3.44755 3.33493 3.37036 3.13855 3.37036 2.93379V0.77205C3.37036 0.56729 3.44755 0.370916 3.58496 0.226128C3.72236 0.0813408 3.90872 0 4.10304 0C4.29736 0 4.48372 0.0813408 4.62113 0.226128C4.75853 0.370916 4.83572 0.56729 4.83572 0.77205V2.93379C4.83572 3.03518 4.81677 3.13557 4.77995 3.22924C4.74313 3.32291 4.68916 3.40802 4.62113 3.47971C4.55309 3.55141 4.47232 3.60827 4.38343 3.64707C4.29453 3.68587 4.19926 3.70584 4.10304 3.70584Z" fill="#373737" />
    <path d="M12.4558 18.2204H3.07751C2.2616 18.2196 1.47934 17.8777 0.90241 17.2698C0.32548 16.6619 0.00102008 15.8376 0.000244141 14.9778L0.000244141 5.4044C0.00102008 4.54466 0.32548 3.72036 0.90241 3.11243C1.47934 2.5045 2.2616 2.16261 3.07751 2.16179H12.4558C13.2717 2.16261 14.054 2.5045 14.6309 3.11243C15.2079 3.72036 15.5323 4.54466 15.5331 5.4044V14.9778C15.5323 15.8376 15.2079 16.6619 14.6309 17.2698C14.054 17.8777 13.2717 18.2196 12.4558 18.2204ZM3.07751 3.70589C2.65024 3.70671 2.2407 3.88592 1.93858 4.20427C1.63645 4.52263 1.46638 4.95418 1.46561 5.4044V14.9778C1.46638 15.428 1.63645 15.8596 1.93858 16.178C2.2407 16.4963 2.65024 16.6755 3.07751 16.6763H12.4558C12.8831 16.6755 13.2926 16.4963 13.5948 16.178C13.8969 15.8596 14.067 15.428 14.0677 14.9778V5.4044C14.067 4.95418 13.8969 4.52263 13.5948 4.20427C13.2926 3.88592 12.8831 3.70671 12.4558 3.70589H3.07751Z" fill="#373737" />
    <path d="M14.8002 7.72049H0.732681C0.538362 7.72049 0.352002 7.63915 0.214597 7.49436C0.077193 7.34958 0 7.1532 0 6.94844C0 6.74368 0.077193 6.54731 0.214597 6.40252C0.352002 6.25773 0.538362 6.17639 0.732681 6.17639H14.8002C14.9945 6.17639 15.1808 6.25773 15.3182 6.40252C15.4557 6.54731 15.5328 6.74368 15.5328 6.94844C15.5328 7.1532 15.4557 7.34958 15.3182 7.49436C15.1808 7.63915 14.9945 7.72049 14.8002 7.72049Z" fill="#373737" />
    <path d="M10.1111 12.998H5.42189C5.22757 12.998 5.04121 12.9167 4.90381 12.7719C4.7664 12.6271 4.68921 12.4307 4.68921 12.226C4.68921 12.0212 4.7664 11.8248 4.90381 11.6801C5.04121 11.5353 5.22757 11.4539 5.42189 11.4539H10.1111C10.3054 11.4539 10.4917 11.5353 10.6291 11.6801C10.7665 11.8248 10.8437 12.0212 10.8437 12.226C10.8437 12.4307 10.7665 12.6271 10.6291 12.7719C10.4917 12.9167 10.3054 12.998 10.1111 12.998Z" fill="#373737" />
    <path d="M7.76637 15.4683C7.57205 15.4683 7.38569 15.387 7.24829 15.2422C7.11088 15.0974 7.03369 14.9011 7.03369 14.6963V9.75517C7.03369 9.55041 7.11088 9.35404 7.24829 9.20925C7.38569 9.06446 7.57205 8.98312 7.76637 8.98312C7.96069 8.98312 8.14705 9.06446 8.28446 9.20925C8.42186 9.35404 8.49905 9.55041 8.49905 9.75517V14.6963C8.49905 14.9011 8.42186 15.0974 8.28446 15.2422C8.14705 15.387 7.96069 15.4683 7.76637 15.4683Z" fill="#373737" />
  </svg>

);
