import { colors } from '../styles/colors';

type CronometerIconProps = {
  color?: string;
  width?: string;
}

export const CronometerIcon = ({ color = colors.Grey400, width = '174px', ...props }: CronometerIconProps): JSX.Element => (
  <svg {...props} width={width} height={width} viewBox="0 0 174 196" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M151.829 53.1589L157.379 47.6085L160.087 50.3161C161.208 51.4374 162.678 51.9982 164.147 51.9982C165.616 51.9982 167.086 51.4374 168.207 50.3161C170.45 48.074 170.45 44.438 168.207 42.1955L154.671 28.6593C152.43 26.4171 148.794 26.4167 146.551 28.6593C144.308 30.9014 144.308 34.5373 146.551 36.7799L149.258 39.4875L143.708 45.0379C132.365 35.0871 118.821 28.397 104.227 25.4501V11.4844H106.141C109.312 11.4844 111.883 8.91341 111.883 5.74219C111.883 2.57097 109.312 0 106.141 0H67.8594C64.6882 0 62.1172 2.57097 62.1172 5.74219C62.1172 8.91341 64.6882 11.4844 67.8594 11.4844H69.7734V25.4501C55.1795 28.397 41.6352 35.0871 30.2917 45.0383L24.7413 39.4879L27.4489 36.7802C29.6914 34.5377 29.6914 30.9022 27.4489 28.6596C25.2068 26.4171 21.5708 26.4171 19.3283 28.6596L5.79207 42.1959C3.54956 44.4384 3.54956 48.074 5.79207 50.3165C6.91333 51.4377 8.38295 51.9986 9.85218 51.9986C11.3214 51.9986 12.7914 51.4381 13.9123 50.3165L16.6199 47.6089L22.1703 53.1593C8.39481 68.8626 0.867188 88.7849 0.867188 109.867C0.867188 132.874 9.82653 154.504 26.0949 170.772C42.3633 187.041 63.993 196 87 196C110.007 196 131.637 187.041 147.905 170.772C164.173 154.504 173.133 132.874 173.133 109.867C173.133 88.7853 165.605 68.8626 151.829 53.1589ZM81.2578 11.4844H92.7422V23.9273C90.8388 23.8025 88.9248 23.7344 87 23.7344C85.0752 23.7344 83.1612 23.8025 81.2578 23.9273V11.4844ZM87 184.516C45.8389 184.516 12.3516 151.028 12.3516 109.867C12.3516 68.706 45.8389 35.2188 87 35.2188C128.161 35.2188 161.648 68.706 161.648 109.867C161.648 151.028 128.161 184.516 87 184.516Z"
      fill={color}
    />
  </svg>
);
