type LowTemperatureIconProps = {
  color?: string;
};

export const LowTemperatureIcon = ({ color = 'white', ...props }: LowTemperatureIconProps): JSX.Element => (
  <svg {...props} width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M7 5H3C2.44772 5 2 5.44772 2 6C2 6.55228 2.44772 7 3 7H7C7.55228 7 8 6.55228 8 6C8 5.44772 7.55228 5 7 5Z"
      fill={color}
    />
    <path
      d="M14 22C12.9506 22 11.9278 21.6698 11.0764 21.0562C10.2251 20.4426 9.58844 19.5767 9.25658 18.5811C8.92473 17.5856 8.91453 16.5108 9.22743 15.5092C9.54033 14.5075 10.1605 13.6296 11 13V5C11 4.20435 11.3161 3.44129 11.8787 2.87868C12.4413 2.31607 13.2044 2 14 2C14.7956 2 15.5587 2.31607 16.1213 2.87868C16.6839 3.44129 17 4.20435 17 5V13C17.8395 13.6296 18.4597 14.5075 18.7726 15.5092C19.0855 16.5108 19.0753 17.5856 18.7434 18.5811C18.4116 19.5767 17.7749 20.4426 16.9236 21.0562C16.0722 21.6698 15.0494 22 14 22ZM15 9.54V5C15.0021 4.87117 14.9774 4.74332 14.9275 4.62454C14.8776 4.50576 14.8035 4.39865 14.71 4.31C14.6182 4.2137 14.5082 4.13668 14.3862 4.08345C14.2643 4.03022 14.133 4.00185 14 4C13.7348 4 13.4804 4.10536 13.2929 4.29289C13.1054 4.48043 13 4.73478 13 5V9.54H15Z"
      fill={color}
    />
  </svg>
);
