type UrgentMaintenanceIconProps = {
  color?: string;
}

export const UrgentMaintenanceIcon = ({ color = 'white', ...props }: UrgentMaintenanceIconProps): JSX.Element => (
  <svg {...props} width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M19.795 4.32335C19.6931 3.91608 19.1855 3.77433 18.8888 4.07121L16.4794 6.48071C15.9443 7.01554 15.0769 7.01554 14.5419 6.48071L13.4364 5.37518C12.9014 4.84019 12.9014 3.97281 13.4364 3.43773L15.8166 1.05755C16.1154 0.758708 15.97 0.246207 15.5587 0.149891C14.9954 0.0182788 14.4001 -0.0303087 13.7862 0.0186878C11.1473 0.229234 8.71853 2.70364 8.55429 5.3459C8.49208 6.34804 8.69457 7.29541 9.08912 8.13461L0.666045 15.3729C0.287201 15.6984 0.0223238 16.001 0.00134277 16.5C-0.0198836 16.999 0.204667 17.6497 0.554637 18.006L1.98208 19.4596C2.33544 19.8196 2.82337 20.0153 3.3276 19.9991C3.83188 19.9827 4.30623 19.7563 4.63579 19.3745L11.9316 10.9214C12.7462 11.285 13.6579 11.4704 14.6206 11.4104C17.2807 11.2444 19.7556 8.79615 19.9493 6.13814C19.9951 5.50847 19.9387 4.89871 19.795 4.32335Z"
      fill={color}
    />
  </svg>
);
