type InfoLinkIconProps = {
  width?: string;
};

export const InfoLinkIcon = ({ width }: InfoLinkIconProps): JSX.Element => (
  <svg width={width || '13'} height="13" viewBox="0 0 13 13" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M10.3182 2.03167L10.3182 2.18167L10.3182 2.03167C10.1674 2.03167 10.0229 2.09155 9.91626 2.19815L6.07016 6.04425C5.96356 6.15084 5.90368 6.29542 5.90368 6.44617C5.90368 6.59692 5.96356 6.74149 6.07016 6.84809C6.17675 6.95468 6.32133 7.01457 6.47208 7.01457C6.62283 7.01457 6.7674 6.95468 6.874 6.84809L10.7201 3.00199C10.8267 2.89539 10.8866 2.75082 10.8866 2.60007C10.8866 2.44932 10.8267 2.30474 10.7201 2.19815C10.6135 2.09155 10.4689 2.03167 10.3182 2.03167Z" fill="#363BC4" stroke="#363BC4" strokeWidth="0.3" />
    <path d="M11.3907 5.25396C11.4436 5.20133 11.4856 5.13878 11.5143 5.06988C11.543 5.00085 11.5578 4.92681 11.5577 4.85204C11.5577 4.85202 11.5577 4.852 11.5577 4.85198L11.5577 2.01184H11.5577L11.5577 2.01084C11.5567 1.86053 11.4966 1.71665 11.3903 1.61036C11.284 1.50407 11.1401 1.44392 10.9898 1.44291L10.9888 1.44291L8.14861 1.44291C7.99772 1.44291 7.85301 1.50285 7.74631 1.60955C7.63962 1.71624 7.57968 1.86095 7.57968 2.01184C7.57968 2.16273 7.63962 2.30744 7.74631 2.41413C7.85301 2.52083 7.99772 2.58077 8.14861 2.58077L10.4199 2.58077L10.4199 4.85204L10.4199 4.85204L10.4199 4.85304C10.4209 5.00335 10.481 5.14723 10.5873 5.25352C10.6936 5.35981 10.8375 5.41996 10.9878 5.42096L10.9878 5.42097L10.9887 5.42097C11.0635 5.421 11.1376 5.40622 11.2066 5.3775C11.2755 5.34884 11.3381 5.30685 11.3907 5.25396ZM11.3907 5.25396C11.3906 5.25408 11.3905 5.2542 11.3904 5.25432L11.2847 5.14789L11.3911 5.25359C11.391 5.25371 11.3908 5.25384 11.3907 5.25396Z" fill="#363BC4" stroke="#363BC4" strokeWidth="0.3" />
    <path d="M9.02528 12.1501H9.02542C9.52582 12.1497 10.0056 11.9507 10.3594 11.5968C10.7132 11.243 10.9122 10.7632 10.9127 10.2628V10.2627V7.11878C10.9127 6.96929 10.8533 6.82591 10.7476 6.7202C10.6419 6.6145 10.4985 6.55511 10.349 6.55511C10.1995 6.55511 10.0562 6.61449 9.95046 6.7202C9.84475 6.82591 9.78536 6.96929 9.78536 7.11878V10.2624C9.785 10.464 9.70477 10.6572 9.56226 10.7997C9.41974 10.9422 9.22655 11.0224 9.02501 11.0228H2.7377C2.53616 11.0224 2.34297 10.9422 2.20045 10.7997C2.05797 10.6572 1.97775 10.4641 1.97735 10.2626V3.975C1.97775 3.7735 2.05797 3.58037 2.20045 3.43788C2.34294 3.2954 2.53608 3.21517 2.73758 3.21478H5.88136C6.03085 3.21478 6.17422 3.15539 6.27993 3.04968C6.38564 2.94397 6.44503 2.8006 6.44503 2.6511C6.44503 2.50161 6.38564 2.35823 6.27993 2.25252C6.17422 2.14681 6.03085 2.08743 5.88136 2.08743L2.73743 2.08743L2.73729 2.08743C2.23689 2.0879 1.75713 2.28689 1.4033 2.64073C1.04947 2.99456 0.850476 3.47432 0.85 3.97472V3.97486L0.85 10.2627L0.85 10.2628C0.850476 10.7632 1.04947 11.243 1.4033 11.5968C1.75713 11.9507 2.23689 12.1497 2.73729 12.1501H2.73743H9.02528Z" fill="#363BC4" stroke="#363BC4" strokeWidth="0.3" />
  </svg>
);
