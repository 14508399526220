type CalendarSimpleIconProps = {
  color?: string;
  width?: string;
}

export const CalendarSimpleIcon = ({ color, width = '18px', ...props }: CalendarSimpleIconProps): JSX.Element => (
  <svg {...props} width={width} height={width} viewBox="0 0 19 21" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M18.2266 8.33333H1.72656M13.6432 1V4.66667M6.3099 1V4.66667M6.12656 19.3333H13.8266C15.3667 19.3333 16.1368 19.3333 16.725 19.0336C17.2425 18.7699 17.6632 18.3493 17.9268 17.8318C18.2266 17.2435 18.2266 16.4735 18.2266 14.9333V7.23333C18.2266 5.69319 18.2266 4.92312 17.9268 4.33486C17.6632 3.81741 17.2425 3.39672 16.725 3.13307C16.1368 2.83333 15.3667 2.83333 13.8266 2.83333H6.12656C4.58642 2.83333 3.81635 2.83333 3.22809 3.13307C2.71064 3.39672 2.28995 3.81741 2.02629 4.33486C1.72656 4.92312 1.72656 5.69319 1.72656 7.23333V14.9333C1.72656 16.4735 1.72656 17.2435 2.02629 17.8318C2.28995 18.3493 2.71064 18.7699 3.22809 19.0336C3.81635 19.3333 4.58642 19.3333 6.12656 19.3333Z"
      stroke={color}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
