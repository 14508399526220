type GraphIconProps = {
  color?: string;
}

export const GraphIcon = ({ color, ...props }: GraphIconProps): JSX.Element => (
  <svg {...props} width="20" height="20" viewBox="0 0 24 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M8.68231 0.602326V6.03795C8.68231 6.37094 8.95164 6.64028 9.28464 6.64028H11.4001V8.27586H3.32014C2.98715 8.27586 2.71781 8.54519 2.71781 8.87819V11.028H0.602326C0.269333 11.028 0 11.2973 0 11.6303V17.0659C0 17.3989 0.269333 17.6682 0.602326 17.6682H6.03795C6.37094 17.6682 6.64028 17.3989 6.64028 17.0659V11.6303C6.64028 11.2973 6.37094 11.028 6.03795 11.028H3.91757V9.47562H11.3952V11.028H9.27974C8.94674 11.028 8.67741 11.2973 8.67741 11.6303V17.0659C8.67741 17.3989 8.94674 17.6682 9.27974 17.6682H14.7154C15.0484 17.6682 15.3177 17.3989 15.3177 17.0659V11.6303C15.3177 11.2973 15.0484 11.028 14.7154 11.028H12.5999V9.47562H20.0775V11.028H17.962C17.6291 11.028 17.3597 11.2973 17.3597 11.6303V17.0659C17.3597 17.3989 17.6291 17.6682 17.962 17.6682H23.3977C23.7307 17.6682 24 17.3989 24 17.0659V11.6303C24 11.2973 23.7307 11.028 23.3977 11.028H21.2822V8.87819C21.2822 8.54519 21.0129 8.27586 20.6799 8.27586H12.5999V6.64028H14.7154C15.0484 6.64028 15.3177 6.37094 15.3177 6.03795V0.602326C15.3177 0.269333 15.0484 0 14.7154 0H9.27974C8.95164 0 8.68231 0.269333 8.68231 0.602326ZM5.43563 16.4587H1.20465V12.2228H5.43563V16.4587ZM14.113 16.4587H9.87717V12.2228H14.113V16.4587ZM22.7904 16.4587H18.5595V12.2228H22.7953V16.4587H22.7904ZM9.88206 1.19976H14.1179V5.43563H9.88206V1.19976Z"
      fill={color || '#4C87C7'}
    />
  </svg>
);
