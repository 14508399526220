interface ITimezone {
  color?: string,
  width?: string,
  height?: string
}

export const TimezoneIcon = ({ color, width, height }: ITimezone): JSX.Element => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width ?? '15'}
    height={height ?? '15'}
    fill="none"
    viewBox="0 0 15 15"
  >
    <path
      stroke={color ?? '#8B8B8B'}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.2"
      d="M1.671 4.422l2.676 1.91c.14.1.209.15.282.161.064.01.13 0 .188-.029.066-.033.117-.101.22-.238l.565-.754a.653.653 0 01.06-.075.313.313 0 01.05-.04.652.652 0 01.087-.042l2.414-1.072c.1-.045.15-.067.188-.102a.312.312 0 00.077-.11.695.695 0 00.03-.211l.177-2.652m-.509 7.008l1.632.7c.19.081.284.122.335.189.044.06.065.132.06.206-.004.083-.062.17-.176.34l-.767 1.151c-.054.081-.081.122-.117.151a.312.312 0 01-.107.058c-.045.013-.093.013-.191.013H7.6c-.13 0-.194 0-.25-.021a.311.311 0 01-.124-.09c-.038-.046-.059-.107-.1-.23l-.445-1.335c-.024-.072-.036-.108-.039-.145a.312.312 0 01.007-.097.667.667 0 01.06-.139l.337-.676c.069-.137.103-.205.156-.248a.312.312 0 01.163-.067c.067-.008.14.017.286.065l.525.175zm5.304-.936A6.24 6.24 0 111 7.24a6.24 6.24 0 0112.48 0z"
    />
  </svg>
);
