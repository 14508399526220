import styled from 'styled-components';

type ExportDocumentIconProps = {
  color?: string;
}

export const ExportDocumentIcon = ({ color, ...props }: ExportDocumentIconProps): JSX.Element => (
  <svg {...props} viewBox="-23 0 512 512" fill="none" xmlns="http://www.w3.org/2000/svg">
    <IconPath
      fill={color || '#000000'}
      d="M 348.945312 221.640625 L 348.945312 124.746094 C 348.945312 121.972656 347.664062 119.410156 345.851562 117.382812 L 237.21875 3.308594 C 235.191406 1.175781 232.308594 0 229.429688 0 L 57.195312 0 C 25.398438 0 0 25.929688 0 57.730469 L 0 383.414062 C 0 415.214844 25.398438 440.71875 57.195312 440.71875 L 193.148438 440.71875 C 218.863281 483.402344 265.605469 512 318.851562 512 C 399.738281 512 465.792969 446.265625 465.792969 365.273438 C 465.902344 294.523438 415.105469 235.40625 348.945312 221.640625 Z M 240.101562 37.457031 L 312.984375 114.179688 L 265.710938 114.179688 C 251.625 114.179688 240.101562 102.550781 240.101562 88.464844 Z M 57.195312 419.375 C 37.242188 419.375 21.34375 403.367188 21.34375 383.414062 L 21.34375 57.730469 C 21.34375 37.667969 37.242188 21.34375 57.195312 21.34375 L 218.757812 21.34375 L 218.757812 88.464844 C 218.757812 114.394531 239.78125 135.523438 265.710938 135.523438 L 327.601562 135.523438 L 327.601562 218.863281 C 324.402344 218.757812 321.839844 218.4375 319.066406 218.4375 C 281.824219 218.4375 247.570312 232.738281 221.746094 255.148438 L 86.222656 255.148438 C 80.351562 255.148438 75.550781 259.949219 75.550781 265.816406 C 75.550781 271.6875 80.351562 276.488281 86.222656 276.488281 L 201.898438 276.488281 C 194.320312 287.160156 188.023438 297.832031 183.117188 309.570312 L 86.222656 309.570312 C 80.351562 309.570312 75.550781 314.371094 75.550781 320.242188 C 75.550781 326.109375 80.351562 330.914062 86.222656 330.914062 L 176.179688 330.914062 C 173.511719 341.585938 172.125 353.429688 172.125 365.273438 C 172.125 384.480469 175.859375 403.476562 182.582031 419.484375 L 57.195312 419.484375 Z M 318.960938 490.765625 C 249.8125 490.765625 193.574219 434.527344 193.574219 365.378906 C 193.574219 296.230469 249.703125 239.992188 318.960938 239.992188 C 388.214844 239.992188 444.34375 296.230469 444.34375 365.378906 C 444.34375 434.527344 388.109375 490.765625 318.960938 490.765625 Z M 318.960938 490.765625"
    />
    <IconPath
      fill={color || '#000000'}
      d="M 86.222656 223.027344 L 194.320312 223.027344 C 200.191406 223.027344 204.992188 218.222656 204.992188 212.355469 C 204.992188 206.484375 200.191406 201.683594 194.320312 201.683594 L 86.222656 201.683594 C 80.351562 201.683594 75.550781 206.484375 75.550781 212.355469 C 75.550781 218.222656 80.351562 223.027344 86.222656 223.027344 Z M 86.222656 223.027344"
    />
    <IconPath
      fill={color || '#000000'}
      d="M 373.59375 363.136719 L 329.738281 410.410156 L 329.738281 293.882812 C 329.738281 288.011719 324.933594 283.210938 319.066406 283.210938 C 313.195312 283.210938 308.394531 288.011719 308.394531 293.882812 L 308.394531 410.410156 L 264.214844 363.136719 C 260.160156 358.871094 253.332031 358.550781 249.0625 362.605469 C 244.792969 366.660156 244.472656 373.382812 248.53125 377.652344 L 310.957031 444.773438 C 312.984375 446.90625 315.757812 448.1875 318.746094 448.1875 C 321.734375 448.1875 324.507812 446.90625 326.535156 444.773438 L 389.070312 377.652344 C 393.125 373.382812 392.910156 366.554688 388.640625 362.605469 C 384.265625 358.550781 377.652344 358.871094 373.59375 363.136719 Z M 373.59375 363.136719"
    />
  </svg>
);

const IconPath = styled.path`
  fill-opacity: 1;
  stroke: none;
`;
