type ZoomMoreIconProps = {
  color?: string;
}

export const ZoomMoreIcon = ({ color = '#363BC4', ...props }: ZoomMoreIconProps): JSX.Element => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M8.54315 7.05127H4.20389C4.02407 7.05127 3.85161 6.97984 3.72446 6.85269C3.59731 6.72553 3.52588 6.55308 3.52588 6.37326C3.52588 6.19344 3.59731 6.02099 3.72446 5.89384C3.85161 5.76668 4.02407 5.69525 4.20389 5.69525H8.54315C8.72296 5.69525 8.89542 5.76668 9.02257 5.89384C9.14972 6.02099 9.22115 6.19344 9.22115 6.37326C9.22115 6.55308 9.14972 6.72553 9.02257 6.85269C8.89542 6.97984 8.72296 7.05127 8.54315 7.05127Z"
      fill={color}
    />
    <path
      d="M6.37332 9.22091C6.1935 9.22091 6.02105 9.14948 5.8939 9.02233C5.76675 8.89517 5.69531 8.72272 5.69531 8.5429V4.20364C5.69531 4.02382 5.76675 3.85137 5.8939 3.72422C6.02105 3.59707 6.1935 3.52563 6.37332 3.52563C6.55314 3.52563 6.72559 3.59707 6.85275 3.72422C6.9799 3.85137 7.05133 4.02382 7.05133 4.20364V8.5429C7.05133 8.72272 6.9799 8.89517 6.85275 9.02233C6.72559 9.14948 6.55314 9.22091 6.37332 9.22091Z"
      fill={color}
    />
    <path
      d="M6.37329 12.7466C5.11277 12.7466 3.88056 12.3728 2.83248 11.6725C1.7844 10.9722 0.967519 9.9768 0.48514 8.81224C0.00276147 7.64767 -0.123451 6.36622 0.122464 5.12992C0.368378 3.89362 0.975375 2.75801 1.86669 1.86669C2.75801 0.975375 3.89362 0.368378 5.12992 0.122464C6.36622 -0.123451 7.64767 0.00276147 8.81224 0.48514C9.9768 0.967519 10.9722 1.7844 11.6725 2.83248C12.3728 3.88056 12.7466 5.11277 12.7466 6.37329C12.7451 8.06315 12.0732 9.68338 10.8783 10.8783C9.68338 12.0732 8.06315 12.7451 6.37329 12.7466ZM6.37329 1.35602C5.38097 1.35602 4.41093 1.65028 3.58584 2.20158C2.76076 2.75289 2.11768 3.53648 1.73794 4.45326C1.35819 5.37005 1.25883 6.37885 1.45243 7.35211C1.64602 8.32536 2.12387 9.21935 2.82554 9.92103C3.52722 10.6227 4.42121 11.1006 5.39447 11.2941C6.36772 11.4877 7.37653 11.3884 8.29331 11.0086C9.2101 10.6289 9.99369 9.98582 10.545 9.16073C11.0963 8.33565 11.3906 7.36561 11.3906 6.37329C11.3891 5.04307 10.8601 3.76774 9.91945 2.82713C8.97884 1.88652 7.70351 1.35746 6.37329 1.35602Z"
      fill={color}
    />
    <path
      d="M15.3229 16.001C15.2338 16.0013 15.1455 15.984 15.0631 15.95C14.9807 15.916 14.9059 15.8661 14.8429 15.803L9.92051 10.8807C9.7932 10.7534 9.72168 10.5807 9.72168 10.4007C9.72168 10.2206 9.7932 10.0479 9.92051 9.92064C10.0478 9.79332 10.2205 9.7218 10.4005 9.7218C10.5806 9.7218 10.7533 9.79332 10.8806 9.92064L15.8029 14.843C15.9291 14.9709 15.9999 15.1433 15.9999 15.323C15.9999 15.5027 15.9291 15.6751 15.8029 15.803C15.7399 15.8661 15.6651 15.916 15.5827 15.95C15.5003 15.984 15.412 16.0013 15.3229 16.001Z"
      fill={color}
    />
  </svg>
);
