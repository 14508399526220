type HistoryIconProps = {
  color?: string;
};

export const HistoryIcon = ({ color, ...props }: HistoryIconProps): JSX.Element => (
  <svg {...props} width="20" height="20" viewBox="-32 0 512 512" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="m432 85.332031c-.851562 0-1.707031.429688-2.558594.640625-1.066406-.210937-1.921875-.640625-2.773437-.640625h-21.335938v-64c0-11.730469-9.597656-21.332031-21.332031-21.332031h-320c-31.574219 0-57.8125 23.039062-62.933594 53.332031-.640625 1.707031-1.066406 3.414063-1.066406 5.335938v394.664062c0 32.429688 26.238281 58.667969 58.667969 58.667969h373.332031c8.746094 0 16-7.253906 16-16v-394.667969c0-8.746093-7.253906-16-16-16zm-208 352c-76.375 0-138.667969-62.292969-138.667969-138.664062 0-76.375 62.292969-138.667969 138.667969-138.667969s138.667969 62.292969 138.667969 138.667969c0 76.371093-62.292969 138.664062-138.667969 138.664062zm138.667969-352h-298.667969c-11.734375 0-21.332031-9.597656-21.332031-21.332031s9.597656-21.332031 21.332031-21.332031h298.667969zm0 0"
      fill={color || '#5D5D5D'}
    />
    <path
      d="m256 352c-5.460938 0-10.921875-2.089844-15.082031-6.25l-32-32c-4.011719-3.988281-6.25-9.410156-6.25-15.082031v-64c0-11.796875 9.554687-21.335938 21.332031-21.335938s21.332031 9.539063 21.332031 21.335938v55.167969l25.75 25.75c8.34375 8.339843 8.34375 21.820312 0 30.164062-4.160156 4.160156-9.621093 6.25-15.082031 6.25zm0 0"
      fill={color || '#5D5D5D'}
    />
  </svg>
);
